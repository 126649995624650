import React from "react";
import { Box } from "@mui/material";
import Breadcrumb from "components/common/Breadcrumb";

/**
 * @deprecated deleted
 */
const ContentWithBreadCrumb = props => {
    const { children, hide_breadcrumb } = props;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {!hide_breadcrumb && <Breadcrumb />}
            {children}
        </Box>
    );
};

export default ContentWithBreadCrumb;
