import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const PREFIX = "SlopedTitle";

const classes = {
    root: `${PREFIX}Root`,
    title: `${PREFIX}Title`,
    text: `${PREFIX}Text`
};

const Root = styled("div")(({ theme }) => ({
    fontStyle: "italic",
    display: "flex",
    justifyContent: "center",
    [`& .${classes.title}`]: style => ({
        lineHeight: "3rem",
        fontSize: "3rem",
        fontWeight: "bold",
        color: theme.palette.common[style.titleColor],
        transform: "skewY(-5deg)",
        marginBottom: "15px"
    }),

    [`& .${classes.text}`]: style => ({
        lineHeight: "2.5rem",
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: theme.palette.common[style.textColor],
        transform: "skewY(-5deg)"
    })
}));

const DEFAULT_TITLE_COLOR = "green";
const DEFAULT_TEXT_COLOR = "black";

const SlopedTitle = props => {
    const { title, titleColor = DEFAULT_TITLE_COLOR, text, textColor = DEFAULT_TEXT_COLOR } = props;

    return (
        <Root style={{ titleColor: titleColor, textColor: textColor }}>
            <Typography className={`${classes.title}`}>{title.toUpperCase()}</Typography>
            <Typography className={`${classes.text}`}>{text.toUpperCase()}</Typography>
        </Root>
    );
};

export default SlopedTitle;
