const colors = {
    lightblue: "#E4F1F8",
    blue: "#0071B5",
    blue_hover: "#005fa0",
    orange: "#EC6607",
    orange_hover: "#d85d07",
    red: "#E12921",
    red_hover: "#cd2720",
    yellow: "#FFEA28",
    black: "#000000",
    darkgrey: "#242323",
    // grey: "#575D5E",
    lightgrey: "#F7F7F7",
    lightgrey2: "#E7E7E7",
    white: "#FFFFFF",
    admin_lightgrey: "#9C9C9C",
    admin_darkgrey: "#393939",
    admin_grey: "#454545",
    admin_blue: "#3482C3",
    admin_orange: "#EC6509",
    admin_green: "#99B33F",
    admin_red: "#E12922",
    admin_yellow: "#E8E128",
    admin_purple: "#AF69DB",

    grey_hover: "#e6e6e6",
    grey_border: "#e0e0e0",

    my_result: "#0071b5",
    my_team: "#004876",
    united: "#976300",
    my_country: "#80559F",

    yes_totally: "#6E8221",
    yes: "#99B33E",
    no: "#EDDC00",
    not_at_all: "#E42613",
    five_choice: "#DBDBDB",

    // Refonte du front
    newBlue: "#00537d",
    newLightBlue: "#40bfff",

    newOrange: "#ff600a",
    newLightOrange: "#ffaf85",

    newGreen: "#02be8a",
    newLightGreen: "#62fdd2",

    newGrey: "#dfe3e8",
    newLightGrey: "#f4f6f8",

    newDarkGrey: "#4e4c4c",
    borderLightGrey: "#eff1f3",

    // results
    myLegend: "#007DBC",
    teamLegend: "#00537D",
    countryLegend: "#826AF9",
    unitedLegend: "#FF6C40",
    // thematics table
    pleasureThematic: "#FFF58D80",
    globalThematic: "#7EBEE080",
    // questions table
    yesCompletelyBgColor: "#029E7333",
    yesMostlyBgColor: "#02BE8A33",
    notReallyBgColor: "#FFEA284D",
    notAtAllBgColor: "#FF48424D",
    othersBgColor: "#DFE3E8",

    // refonte
    grey: "rgb(156, 156, 156)",
    started: "#2ecc71",
    pause: "#f1c40f",
    warning: "#c0392b",
    completed: "#2980b9"
};

export default colors;
