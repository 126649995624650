import React from "react";

import InfoTooltip from "./InfoTooltip";
import LeftBar from "./LeftBar";
import Breadcrumb from "./Breadcrumb";
import ComponentWithLabel from "./ComponentWithLabel";
import ComponentWithTooltip from "./ComponentWithTooltip";
import DatePickerDTB from "./DatePickerDTB";
import DatePickerStartEnd from "./DatePickerStartEnd";
import Stepper from "./Stepper";
import ProgressWithDescription from "./ProgressWithDescription";
import ResultsRadar from "./ResultsRadar";
import RadarLegends from "./ResultsRadar/RadarLegends";
import TabsContainer from "./TabsContainer";
import AutoComplete from "./AutoComplete";
import AutoCompleteWithCreation from "./AutoCompleteWithCreation";
import SnackbarDTB from "./SnackbarDTB";

import OutlinedButton from "./buttons/OutlinedButton";
import TextButton from "./buttons/TextButton";
import ContainedButton from "./buttons/ContainedButton";
import FabButton from "./buttons/FabButton";
import UploadButton from "./buttons/UploadButton";
import InputNumber from "./inputs/InputNumber";
import InputLabel from "./inputs/InputLabel";
import SlopedTitle from "./texts/SlopedTitle";
import Label from "./texts/Label";
import Title from "./texts/Title";
import PrimaryTitle from "./texts/Title/PrimaryTitle";
import Text from "./texts/Text";

const Topbar = React.lazy(() => import("./Topbar"));
const TopbarAdmin = React.lazy(() => import("./TopbarAdmin"));

export {
    Topbar,
    TopbarAdmin,
    InfoTooltip,
    LeftBar,
    Breadcrumb,
    ComponentWithLabel,
    ComponentWithTooltip,
    DatePickerDTB,
    DatePickerStartEnd,
    Stepper,
    ProgressWithDescription,
    ResultsRadar,
    RadarLegends,
    TabsContainer,
    AutoComplete,
    AutoCompleteWithCreation,
    SnackbarDTB,
    OutlinedButton,
    TextButton,
    ContainedButton,
    FabButton,
    UploadButton,
    InputNumber,
    InputLabel,
    SlopedTitle,
    Label,
    Title,
    PrimaryTitle,
    Text
};
