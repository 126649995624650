import { getId } from "utils";

/**
 * Map campaign redux state object to Campaign API JSON.
 *
 * @param {Object} campaign
 * @param {Object} titleTranslation The title have to be translated by translation API before.
 * @returns JsonApi Object
 */
export const fitCampaignJsonApi = (campaign, titleTranslation) => ({
    title: titleTranslation["@id"],
    dateOfDispatch: campaign.dateOfDispatch,
    survey: campaign.survey["@id"],
    state: campaign.state,
    countries: campaign.countries,
    generatedQuestion: campaign.generatedQuestion["@id"],
    answerType: campaign.answerType,
    branch: [],
    service: [],
    files: campaign.files.map(f => f["@id"] || getId(f))
});
