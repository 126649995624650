export const campaignPageModeSelector = state => {
    return state.campaigns.campaignPageMode;
};

export const campaignSelector = state => {
    return state.campaigns.campaign;
};

export const campaignEditingSelector = state => {
    return state.campaigns.campaignEditing;
};

export const flagDuplicateSelector = state => {
    return state.campaigns.flagDuplicate;
};

export const flagModificationSelector = state => {
    return state.campaigns.flagModification;
};
