import useAuthentication from "hooks/useAuthentication";
import useAuthorization from "hooks/useAuthorization";
import useUserFlow from "hooks/useUserFlow";
import { isEmpty } from "lodash";
import { Redirect, Route } from "react-router-dom";
import { userProfileStore } from "store/store";
import * as URLS from "./Urls";

const PrivateRoute = props => {
    const { Component, roles, ...rest } = props;

    const myProfile = userProfileStore(state => state.myProfile);
    const { resumeUserFlow } = useUserFlow();
    const { can } = useAuthorization();
    const { removeAuthInfos, token } = useAuthentication();
    const userIsAuth = () => token.isValid() && !isEmpty(myProfile);
    const userIsAllowed = () => (!roles ? true : roles.some(role => can(role)));

    return (
        <Route
            {...rest}
            render={props => {
                if (userIsAuth()) {
                    if (userIsAllowed()) {
                        resumeUserFlow();
                        return <Component {...props} />;
                    } else {
                        return <Redirect to={URLS.NOT_ALLOWED_PAGE} replace />;
                    }
                } else {
                    removeAuthInfos();
                    return <Redirect to={URLS.SIGN_IN_URL} />;
                }
            }}
        />
    );
};

export default PrivateRoute;
