import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PREFIX = "OutlinedButton";

const classes = {
    leftIcon: `${PREFIX}LeftIcon`,
    rightIcon: `${PREFIX}RightIcon`
};

const StyledButton = styled(Button)(({ theme, style }) => ({
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: "bold",

    [`& .${classes.leftIcon}`]: {
        fontSize: 20,
        marginRight: theme.spacing(1)
    },

    [`& .${classes.rightIcon}`]: {
        fontSize: 20,
        marginLeft: theme.spacing(1)
    }
}));

const OutlinedButton = props => {
    const { label, LeftIcon, RightIcon, className, onClick, disabled } = props;

    return (
        <StyledButton
            onClick={onClick}
            variant="outlined"
            disabled={disabled}
            className={className}>
            {LeftIcon && <LeftIcon className={classes.leftIcon} />}
            {label}
            {RightIcon && <RightIcon className={classes.rightIcon} />}
        </StyledButton>
    );
};

export default OutlinedButton;
