import { SET_QUESTION_TYPES } from "./types";

const stateInitial = [];

const question_types = (state = stateInitial, action) => {
    switch (action.type) {
        case SET_QUESTION_TYPES: {
            return [...action.payload];
        }

        default: {
            return state;
        }
    }
};

export default question_types;
