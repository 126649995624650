const states = {
    // state: value, //TYPES_REDUX
    step: 0, //SET_STEP
    sub_step: 0, //SET_SUB_STEP
    who_is_in_your_team: [], //SET_WHO_IS_IN_MY_TEAM
    loader_who_is_in_your_team: false, //SET_LOADER_WHO_IS_IN_MY_TEAM
    total_perimeter: [], //SET_TOTAL_PERIMETER
    loader_total_perimeter: false, //SET_LOADER_TOTAL_PERIMETER
    prog_n1_my_team: [], //SET_PROG_N1_MY_TEAM
    loader_prog_n1_my_team: false, //SET_LOADER_PROG_N1_MY_TEAM
    prog_n1_total_perimeter: [], //SET_PROG_N1_TOTAL_PERIMETER
    loader_prog_n1_total_perimeter: false, //SET_LOADER_PROG_N1_TOTAL_PERIMETER
    my_team_parameter: [], //SET_MY_TEAM_PARAMETER
    my_total_team_parameter: [], //SET_MY_TOTAL_TEAM_PARAMETER
    loader_update_ldap: false //SET_LOADER_UPDATE_LDAP
};

export default states;

// Redux Selectors
// export const stepSelector = state => state.team.step;
// export const subStepSelector = state => state.team.sub_step;
// export const whoIsInYourTeamSelector = state => state.team.who_is_in_your_team;
// export const totalPerimeterSelector = state => state.team.total_perimeter;
// export const progN1MyTeamSelector = state => state.team.prog_n1_my_team;
// export const progN1TotalPerimeterSelector = state => state.team.prog_n1_total_perimeter;
// export const myTeamParameterSelector = state => state.team.my_team_parameter;
// export const myTotalTeamParameterSelector = state => state.team.my_total_team_parameter;
// export const loaderWhoIsInYourTeamSelector = state => state.team.loader_who_is_in_your_team;
// export const loaderTotalPerimeterSelector = state => state.team.loader_total_perimeter;
// export const loaderProgN1MyTeamSelector = state => state.team.loader_prog_n1_my_team;
// export const loaderProgN1TotalPerimeterSelector = state =>
//     state.team.loader_prog_n1_total_perimeter;
// export const loaderUpdateLdapSelector = state => state.team.loader_update_ldap;
