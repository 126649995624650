import { Grid, Skeleton, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import useTranslation from "hooks/useTranslation";
import PropTypes from "prop-types";

const PREFIX = "RadarLegends";

const classes = {
    line: `${PREFIX}Line`,
    margin: `${PREFIX}Margin`
};

const Root = styled("div")(() => ({
    [`&.${classes.line}`]: {
        height: "5px",
        width: "25px",
        borderRadius: "25px",
        margin: "5px"
    },

    [`& .${classes.margin}`]: {
        margin: "15px 0"
    }
}));

const Line = styled("div")(({ theme }) => ({
    height: "5px",
    width: "25px",
    borderRadius: "25px",
    margin: "5px"
}));

const RadarLegends = ({
    direction,
    show = ["my", "team", "country", "united"],
    teamLabel = "admin.results.tab_my_team",
    isLoading
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const showLegends = legend => show.includes(legend);
    const showMyLegend = showLegends("my");
    const showTeamLegend = showLegends("team");
    const showCountryLegend = showLegends("country");
    const showUnitedLegend = showLegends("united");

    const isLoadingRender = () => {
        return (
            <Grid container style={{ marginTop: "25px" }} alignItems="center">
                <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                    <Grid item xs={1}>
                        <Skeleton variant="rectangular" className={classes.line} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography style={{ width: "100%" }}>
                            <Skeleton variant="text" />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                    <Grid item xs={1}>
                        <Skeleton variant="rectangular" className={classes.line} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography style={{ width: "100%" }}>
                            <Skeleton variant="text" />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                    <Grid item xs={1}>
                        <Skeleton variant="rectangular" className={classes.line} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography style={{ width: "100%" }}>
                            <Skeleton variant="text" />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                    <Grid item xs={1}>
                        <Skeleton variant="rectangular" className={classes.line} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography style={{ width: "100%" }}>
                            <Skeleton variant="text" />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const lengendsRender = () => {
        const legendMe = t("admin.results.tab_me");
        const legendMyTeam = t(teamLabel);
        const legendMyCountry = t("admin.results.tab_country");
        const legendUnited = "United";

        const horizontalRender = () => (
            <Grid container sx={{ mt: "25px" }} alignItems="center">
                {showMyLegend && (
                    <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.myLegend }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography>{legendMe}</Typography>
                        </Grid>
                    </Grid>
                )}
                {showTeamLegend && (
                    <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.teamLegend }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography>{legendMyTeam}</Typography>
                        </Grid>
                    </Grid>
                )}
                {showCountryLegend && (
                    <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.countryLegend }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography>{legendMyCountry}</Typography>
                        </Grid>
                    </Grid>
                )}
                {showUnitedLegend && (
                    <Grid item xs={12} md={3} container alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.unitedLegend }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography>{legendUnited}</Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );

        const verticalRender = () => (
            <div className={classes.margin}>
                {showMyLegend && (
                    <Grid
                        sx={{ mt: "25px" }}
                        container
                        alignItems="center"
                        direction="row"
                        wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.myLegend }} />
                        </Grid>
                        <Grid item>
                            <Typography>{legendMe}</Typography>
                        </Grid>
                    </Grid>
                )}
                {showTeamLegend && (
                    <Root>
                        <Grid
                            sx={{ mt: "25px" }}
                            container
                            alignItems="center"
                            direction="row"
                            wrap="nowrap">
                            <Grid item>
                                <Line sx={{ backgroundColor: theme.palette.common.teamLegend }} />
                            </Grid>
                            <Grid item>
                                <Typography>{legendMyTeam}</Typography>
                            </Grid>
                        </Grid>
                    </Root>
                )}
                {showCountryLegend && (
                    <Grid
                        sx={{ mt: "25px" }}
                        container
                        alignItems="center"
                        direction="row"
                        wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.countryLegend }} />
                        </Grid>
                        <Grid item>
                            <Typography>{legendMyCountry}</Typography>
                        </Grid>
                    </Grid>
                )}
                {showUnitedLegend && (
                    <Grid
                        sx={{ mt: "25px" }}
                        container
                        alignItems="center"
                        direction="row"
                        wrap="nowrap">
                        <Grid item>
                            <Line sx={{ backgroundColor: theme.palette.common.unitedLegend }} />
                        </Grid>
                        <Grid item>
                            <Typography>{legendUnited}</Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
        );

        return direction === "horizontal" ? horizontalRender() : verticalRender();
    };

    return <Root>{isLoading ? isLoadingRender() : lengendsRender()}</Root>;
};

RadarLegends.propTypes = {
    show: PropTypes.arrayOf(PropTypes.oneOf(["my", "team", "country", "united"])),
    direction: PropTypes.oneOf(["horizontal", "vertical"])
};

RadarLegends.defaultProp = {
    direction: "horizontal",
    show: ["my", "team", "country", "united"]
};

export default RadarLegends;
