import { useHistory } from "react-router-dom";
import userProfileStore from "store/user";
import uxControlStore from "store/uxControl";

const useUserFlow = () => {
    const history = useHistory();
    const myProfile = userProfileStore(state => state.myProfile);
    const previousUserSession = uxControlStore(state => state.previousUserSession);
    const from = uxControlStore(state => state.from);
    const setFrom = uxControlStore(state => state.setFrom);
    const setCurrentUser = uxControlStore(state => state.setCurrentUser);

    /**
     * Save current user flow URL to resume it after.
     * @param {*} param0
     */
    const saveUserFlow = ({ fromUrl }) => {
        setCurrentUser(myProfile.uid);
        setFrom(fromUrl);
    };

    /**
     * Resume the saved user flow if exists.
     * @returns
     */
    const resumeUserFlow = () => {
        if (from && myProfile.uid === previousUserSession) {
            const fromUrl = from;
            setFrom("");
            history.push(fromUrl);
        }
    };

    return { saveUserFlow, resumeUserFlow };
};

export default useUserFlow;
