import React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";
import StepContent from "@mui/material/StepContent";
import { Text } from "components/common";
import { surveysStore } from "store/store";

const PREFIX = "index";

const classes = {
    root: `${PREFIX}Root`,
    link: `${PREFIX}Link`,
    textIcon: `${PREFIX}TextIcon`,
    active_icon: `${PREFIX}ActiveIcon`,
    completed_icon: `${PREFIX}CompletedIcon`,
    fill_white: `${PREFIX}FillWhite`,
    fill_blue: `${PREFIX}FillBlue`,
    next_step: `${PREFIX}NextStep`
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
    backgroundColor: "inherit",
    [`& .${classes.active_icon}`]: {
        fill: theme.palette.common["blue"]
    },
    [`& .${classes.completed_icon}`]: {
        fill: theme.palette.common["admin_lightgrey"]
    },
    [`& .${classes.fill_white}`]: {
        fill: theme.palette.common["white"]
    },
    [`& .${classes.fill_blue}`]: {
        fill: theme.palette.common["blue"]
    },
    [`& .${classes.next_step}`]: {
        fill: "transparent",
        border: `1px solid ${theme.palette.common["blue"]}`,
        borderRadius: "50%"
    }
}));

const StepperDTB = props => {
    const { steps } = props;

    const [step, subStep] = surveysStore(state => [state.step, state.subStep]);

    return (
        <StyledStepper activeStep={step} orientation="vertical">
            {steps.map(({ label, sub_steps }, index) => {
                const is_active = step === index;
                const is_completed = step > index;

                const step_icon_classes = {
                    root: !is_active && !is_completed ? classes.next_step : "",
                    text: is_active || is_completed ? classes.fill_white : classes.fill_blue,
                    active: classes.active_icon,
                    completed: classes.completed_icon
                };

                return (
                    <Step key={index}>
                        <StepLabel
                            StepIconProps={{ classes: step_icon_classes }}
                            StepIconComponent={StepIcon}>
                            <Text
                                variant="stepper_step"
                                color={step > index ? "admin_lightgrey" : "blue"}
                                text={label}
                            />
                        </StepLabel>
                        {sub_steps &&
                            sub_steps.map((sub_step_element, index) => {
                                const is_active = subStep === index;
                                return (
                                    <StepContent key={index}>
                                        <Text
                                            variant="stepper_sub_step"
                                            color={is_active ? "blue" : "admin_lightgrey"}
                                            text={sub_step_element.label}
                                        />
                                    </StepContent>
                                );
                            })}
                    </Step>
                );
            })}
        </StyledStepper>
    );
};

export default StepperDTB;
