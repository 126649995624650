import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { ComponentWithLabel, DatePickerDTB } from "components/common";

const PREFIX = "DatePickerStartEnd";

const classes = {
    root: `${PREFIX}Root`,
    flexContent: `${PREFIX}FlexContent`
};

const Root = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [`& .${classes.flexContent}`]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginRight: "20px"
    }
}));

const DatePickerStartEnd = props => {
    const {
        surveyDateStartValue,
        surveyDateStartOnChange,
        surveyDateEndValue,
        surveyDateEndValueOnChange
    } = props;

    const onChangeStart = startDate => {
        const endDate = moment(startDate).add(1, "d");
        surveyDateStartOnChange(startDate);
        surveyDateEndValueOnChange(endDate);
    };

    const [minEndDate, setMinEndDate] = useState();
    useEffect(() => {
        let now = moment();
        if (now.isBefore(surveyDateStartValue)) {
            setMinEndDate(surveyDateStartValue);
        } else {
            setMinEndDate(now.date());
        }
    }, [surveyDateStartValue]);

    return (
        <Root>
            <div className={classes.flexContent}>
                <ComponentWithLabel label="Date de début">
                    <DatePickerDTB value={surveyDateStartValue} onChange={onChangeStart} />
                </ComponentWithLabel>
            </div>
            <div className={classes.flexContent}>
                <ComponentWithLabel label="Date de fin" className={classes.flexContent}>
                    <DatePickerDTB
                        value={surveyDateEndValue}
                        minDate={minEndDate}
                        onChange={surveyDateEndValueOnChange}
                    />
                </ComponentWithLabel>
            </div>
        </Root>
    );
};

export default DatePickerStartEnd;
