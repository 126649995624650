export const getCheckWhoIsInYourTeam = () => ({
    method: "GET",
    suffix: "/dtb_users/check_who_is_in_your_team?orderBy[state]=ASC&orderBy[jobDate]=ASC"
});

export const getDownloadExtractCheckWhoIsInYourTeam = () => ({
    method: "GET",
    suffix: "/dtb_users/download_check_who_is_in_your_team"
});

export const getCheckTotalPerimeter = () => ({
    method: "GET",
    suffix: "/dtb_users/check_total_perimeter"
});

export const postUpdateMyTeamFromLdap = () => ({
    method: "POST",
    suffix: "/dtb_users/update_my_team_from_ldap",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getProgN1WithTypeAndBossWithGroup = (bossUid, type, group) => ({
    method: "GET",
    suffix: "/team_parameters?bossUid=" + bossUid + "&type=" + type + "&groups[]=" + group
});

export const getMyTeamParameter = (bossUid, type) => ({
    method: "GET",
    suffix: "/team_parameters?bossUid=" + bossUid + "&type=" + type
});

export const getProgN1WithTypeAndTitle = (title, type, group) => ({
    method: "GET",
    suffix: "/team_parameters?title=" + title + "&type=" + type + "&groups[]=" + group
});

export const createTeamParameter = () => ({
    method: "POST",
    suffix: `/team_parameters`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const updateTeamParameter = id => ({
    method: "PUT",
    suffix: `/team_parameters/` + id,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});
