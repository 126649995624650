import React from "react";
import { Box } from "@mui/material";

const CenteredContent = props => {
    const { children } = props;
    return (
        <Box
            sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            {children}
        </Box>
    );
};

export default CenteredContent;
