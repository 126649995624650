import { create } from "zustand";
import actions from "./actions";
import states from "./states";

const tagsStore = create((...a) => ({
    ...states,
    ...actions(...a)
}));

export default tagsStore;
