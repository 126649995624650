import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { modifyQuestions } from "store/questions/endpoints";
import { questionsStore, translationsStore, uiControlStore } from "store/store";
import { createCategories, getCategories, modifyCategories } from "./endpoints";
import { formatCategoryForJsonApi } from "./formatters";
import { no_category_id } from "./utils";

const actions = (set, get) => ({
    editCategory: category => {
        const id = category.id;
        set(state => ({
            categories: [...state.categories.map(c => (c.id == id ? { ...category } : { ...c }))]
        }));
    },
    fetchCategories: () => {
        fetchData(getCategories()).then(res => set({ categories: res }));
    },
    createCategory: async category => {
        const createOneTranslation = translationsStore.getState().createOneTranslation;
        const setSnackbar = uiControlStore.getState().setSnackbar;

        const categoryLabel = await createOneTranslation(
            category.create_category_label,
            "category.name",
            "category"
        );

        const categoryShortLabel = await createOneTranslation(
            category.create_category_short_label,
            "category.short_name",
            "category"
        );
        return fetchData(
            createCategories(),
            formatCategoryForJsonApi(categoryLabel, categoryShortLabel)
        )
            .then(res => {
                set(state => ({
                    categories: [...state.categories, res]
                }));
            })
            .catch(() => setSnackbar(true, "Cannot create category", SNACKBAR_VARIANT_TYPE.ERROR));
    },
    updateCategory: async (initialValues, newValues) => {
        const { create_category_label, create_category_short_label } = newValues;
        const { id, label, shortLabel } = initialValues;

        const setSnackbar = uiControlStore.getState().setSnackbar;
        const editTranslation = translationsStore.getState().editTranslation;

        const themeLabel = await editTranslation(label.id, create_category_label, "en");
        const themeShortLabel = await editTranslation(
            shortLabel.id,
            create_category_short_label,
            "en"
        );
        const fitCategoryToJsonApiValues = formatCategoryForJsonApi(themeLabel, themeShortLabel);
        return fetchData(modifyCategories(id), fitCategoryToJsonApiValues)
            .then(res => {
                get().editCategory(res);
            })
            .catch(() => setSnackbar(true, "Cannot update question", SNACKBAR_VARIANT_TYPE.ERROR));
    },
    disableCategory: async category => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const editQuestion = questionsStore.getState().editQuestion;
        category.questions.map(question => {
            fetchData(modifyQuestions(question.id), { category: no_category_id })
                .then(res => {
                    editQuestion(res);
                })
                .catch(() =>
                    setSnackbar(
                        true,
                        "Cannot change theme of question",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    )
                );
            return true;
        });
        return fetchData(modifyCategories(category.id), { isActive: false })
            .then(res => {
                get().editCategory(res);
                return res;
            })
            .catch(() => setSnackbar(true, "Cannot disable theme", SNACKBAR_VARIANT_TYPE.ERROR));
    },
    activateCategory: async category => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(modifyCategories(category.id), { isActive: true })
            .then(res => {
                get().editCategory(res);
                return res;
            })
            .catch(() => setSnackbar(true, "Cannot disable theme", SNACKBAR_VARIANT_TYPE.ERROR));
    }
});

export default actions;
