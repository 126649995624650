import filter from "lodash/filter";

export const formatProfileData = (
    myProfile,
    languagesList,
    fetchTranslations,
    setSelectedLanguage
) => {
    const preferredLang = myProfile.preferredLanguage != null ? myProfile.preferredLanguage : "en";
    setSelectedLanguage(preferredLang.toLowerCase());

    Promise.all([fetchTranslations(`language=${preferredLang.toLowerCase()}`)]);

    const profileWithFetchedLanguages = {
        ...myProfile,
        profileLanguages: filter(languagesList, lang =>
            myProfile.profileLanguages.includes(lang["@id"])
        )
    };
    return profileWithFetchedLanguages;
};
