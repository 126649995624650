import React from "react";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";

const StyledFab = styled(Fab)(({ theme, styled }) => ({
    boxShadow: "none",
    backgroundColor: theme.palette.common[`${styled.color}`],
    color: `${styled.color !== undefined ? "white" : "black"}`,
    "&:hover": {
        backgroundColor: theme.palette.common[`${styled.colorHover}`],
        color: `${styled.colorHover !== undefined ? "white" : "black"}`
    }
}));

const FabButton = React.forwardRef((props, ref) => {
    const { colorHover, color, icon, className, onClick, disabled, size, type } = props;

    return (
        <StyledFab
            {...props}
            ref={ref}
            disabled={disabled}
            styled={{ colorHover: colorHover, color: color }}
            className={`${className}`}
            onClick={onClick}
            type={type}
            size={size}>
            {icon}
        </StyledFab>
    );
});

export default FabButton;
