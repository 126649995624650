const actions = (set, get) => ({
    setReviews: reviews => set({ reviews: reviews }),
    addReviewingComment: commentId => {
        const reviewingComments = get().reviewingComments;
        set({ reviewingComments: [...reviewingComments, +commentId] });
    },
    setReviewingComments: reviewingComments => set({ reviewingComments: reviewingComments }),
    setIsLoading: value => set({ isLoading: value }),
    setSurveyOpts: opts => set({ surveyOpts: opts }),
    setCountryOpts: opts => set({ countryOpts: opts }),
    getReviewByParams: (surveyId, country) => {
        return get().reviews.find(
            review => review.survey.id == surveyId && review.country == country
        );
    },
    commentIsReviewing: commentId => get().reviewingComments.includes(commentId),
    removeReviewedComment: commentId => {
        const reviews = get().reviews.map(review => ({
            ...review,
            comments: review.comments.filter(comment => comment.id != commentId)
        }));
        const reviewsWithComments = reviews.filter(r => r.comments.length > 0);
        set({ reviews: reviewsWithComments });
    }
});

export default actions;
