import { create } from "zustand";
import actions from "./actions";
import states from "./states";

const mediasStore = create((...a) => ({
    ...states,
    ...actions(...a)
}));

export default mediasStore;
