/**
 * @deprecated Don't use it, use the hook useTranslations.
 * If you need use it, your code logic is not good. Work with translation_key and translate it in the render.
 * @param {*} selected_language
 * @returns
 */
export const tFallback = selected_language => trad => {
    try {
        if (trad.content[selected_language].valid) return trad.content[selected_language].value;
        else return trad.content["en"].value;
    } catch (err) {
        console.log("ERROR TRAD :", trad);
        return trad.content["en"]?.value || "NO TRANSLATION";
    }
};
