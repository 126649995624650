import React from "react";
import { TextField } from "@mui/material";

const InputLabel = props => {
    const { id, required, placeholder, multiline, value = "", onChange = null, center } = props;

    return (
        <TextField
            id={id}
            sx={{ textAlign: center ? "center" : "inherit" }}
            placeholder={placeholder}
            multiline={multiline}
            rows={4}
            fullWidth
            required={required}
            value={value}
            onChange={onChange}
            {...props}></TextField>
    );
};

export default InputLabel;
