import { createTag, getTags } from "./endpoints";
import { uiControlStore } from "store/store";
import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";

const actions = (set, get) => ({
    fetchTags: async () => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        return fetchData(getTags())
            .then(res => set({ tags: res }))
            .catch(() => setSnackbar(true, "Cannot fetch tags", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    createTagThunk: async label => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(createTag(), { label: label })
            .then(res => {
                set(state => ({
                    tags: [...state.tags, res]
                }));
                return res;
            })
            .catch(() => setSnackbar(true, "Cannot create tags", SNACKBAR_VARIANT_TYPE.ERROR));
    }
});

export default actions;
