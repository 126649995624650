import * as TYPES from "./types";

const stateInitial = {
    survey: { id: null, status: null, value: null },
    my: {
        pleasure: { id: null, status: null, value: null },
        questions: { id: null, status: null, value: null },
        thematics: { id: null, status: null, value: null },
        comments: { id: null, status: null, value: null }
    },
    teamZero: {
        access: { id: null, authorize: true },
        pleasure: { id: null, status: null, value: null },
        questions: { id: null, status: null, value: null },
        thematics: { id: null, status: null, value: null },
        comments: { id: null, status: null, value: null },
        participationRate: { id: null, status: null, value: null }
    },
    teamOne: {
        access: { id: null, authorize: true },
        pleasure: { id: null, status: null, value: null },
        questions: { id: null, status: null, value: null },
        thematics: { id: null, status: null, value: null },
        comments: { id: null, status: null, value: null },
        participationRate: { id: null, status: null, value: null }
    },
    teamN: {
        access: { id: null, authorize: true },
        pleasure: { id: null, status: null, value: null },
        questions: { id: null, status: null, value: null },
        thematics: { id: null, status: null, value: null },
        comments: { id: null, status: null, value: null },
        participationRate: { id: null, status: null, value: null }
    },
    country: {
        access: { id: null, authorize: false },
        pleasure: { id: null, status: null, value: null },
        questions: { id: null, status: null, value: null },
        thematics: { id: null, status: null, value: null },
        comments: { id: null, status: null, value: null },
        participationRate: { id: null, status: null, value: null }
    },
    united: {
        access: { id: null, authorize: false },
        pleasure: { id: null, status: null, value: null },
        questions: { id: null, status: null, value: null },
        thematics: { id: null, status: null, value: null }
    },

    selected_filter: 0,
    selected_box_filter: 0,
    me: [],
    team: [],
    surveyTitleTranslation: [],
    team_one: [],
    team_n: [],
    loader: false,
    verifyLoader: [],
    verifyError403Team: false,
    verifyError403TeamOne: false,
    verifyError403TeamN: false,
    mediaContent: "",
    countryNotAuthorized: false,
    fetchingCountryResults: false,
    fetchingTeamsResults: 0
};

const results = (state = stateInitial, action) => {
    switch (action.type) {
        // BEFORE REWORK
        case TYPES.SET_SELECTED_FILTER:
            return {
                ...state,
                selected_filter: action.payload
            };
        case TYPES.SET_SELECTED_BOX_FILTER:
            return {
                ...state,
                selected_box_filter: action.payload
            };
        case TYPES.SET_MY_SURVEY:
            return {
                ...state,
                survey: action.payload
            };
        case TYPES.SET_SURVEY_TITLE_TRANSLATION:
            return {
                ...state,
                surveyTitleTranslation: action.payload
            };
        case TYPES.SET_MY_RESULTS:
            return {
                ...state,
                me: action.payload
            };

        case TYPES.SET_MY_VERIFY_LOADER:
            return {
                ...state,
                verifyLoader: action.payload
            };

        case TYPES.SET_MY_TEAM_RESULTS:
            return {
                ...state,
                team: action.payload
            };

        case TYPES.SET_MY_TEAM_N_RESULTS:
            return {
                ...state,
                team_n: action.payload
            };
        case TYPES.SET_MY_TEAM_1_RESULTS:
            return {
                ...state,
                team_one: action.payload
            };
        case TYPES.SET_MY_COUNTRY_RESULTS:
            return {
                ...state,
                country: action.payload
            };

        case TYPES.SET_MY_COUNTRY_RESULTS_UNITED_BLOOMING:
            return {
                ...state,
                countryUnitedBlooming: action.payload
            };

        case TYPES.SET_MY_COUNTRY_RESULTS_UNITED_THEMATIC:
            return {
                ...state,
                countryUnitedThematic: action.payload
            };

        case TYPES.SET_MY_COUNTRY_RESULTS_UNITED_QUESTION:
            return {
                ...state,
                countryUnitedQuestion: action.payload
            };

        case TYPES.SET_MY_LOADER:
            return {
                ...state,
                loader: action.payload
            };
        case TYPES.SET_FETCHING_COUNTRY:
            return {
                ...state,
                fetchinCountryResults: action.payload
            };
        case TYPES.SET_FETCHING_TEAMS:
            return {
                ...state,
                fetchingTeamsResults: action.payload
            };

        case TYPES.SET_MY_VERIFY_ERROR_403_TEAM:
            return {
                ...state,
                verifyError403Team: action.payload
            };

        case TYPES.SET_MY_VERIFY_ERROR_403_TEAM_ONE:
            return {
                ...state,
                verifyError403TeamOne: action.payload
            };

        case TYPES.SET_MY_VERIFY_ERROR_403_TEAM_N:
            return {
                ...state,
                verifyError403TeamN: action.payload
            };

        case TYPES.SET_MY_MEDIA_CONTENT:
            return {
                ...state,
                mediaContent: action.payload
            };
        case TYPES.SET_COUNTRY_NOT_AUTHORIZED:
            return {
                ...state,
                countryNotAuthorized: action.payload
            };
        // AFTER REWORK
        case TYPES.SET_SURVEY:
            return {
                ...state,
                survey: action.payload
            };
        case TYPES.SET_MY:
            return {
                ...state,
                my: action.payload
            };
        case TYPES.SET_TEAM_0:
            return {
                ...state,
                teamZero: action.payload
            };
        case TYPES.SET_TEAM_1:
            return {
                ...state,
                teamOne: action.payload
            };
        case TYPES.SET_TEAM_N:
            return {
                ...state,
                teamN: action.payload
            };
        case TYPES.SET_COUNTRY:
            return {
                ...state,
                country: action.payload
            };
        case TYPES.SET_UNITED:
            return {
                ...state,
                united: action.payload
            };

        default:
            return state;
    }
};

export default results;
