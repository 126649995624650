import { SET_QUESTION_TYPES } from "./types";
import { fetchData } from "api/fetch";
import { getQuestionTypes } from "./endpoints";

export const setQuestionTypes = questionTypes => ({
    type: SET_QUESTION_TYPES,
    payload: questionTypes
});

export const fetchQuestionTypes = () => dispatch =>
    fetchData(getQuestionTypes()).then(res => dispatch(setQuestionTypes(res)));
