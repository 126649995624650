import campaigns from "domains/campaigns";
import question_types from "domains/questionTypes";
import results from "domains/results";
import roles from "domains/roles";
import surveysManager from "domains/surveysManager";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

const createRootReducer = history =>
    combineReducers({
        form: formReducer,
        question_types,
        roles,
        surveysManager,
        results,
        campaigns
    });

export default createRootReducer;
