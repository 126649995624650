import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import { fetchData } from "api/fetch";
import { createTranslation, modifyTranslation, getTranslations } from "./endpoints";
import { editTranslationToJsonApi, fitTranslationToJsonApi } from "./formatters";
import { uiControlStore } from "store/store";
import { SNACKBAR_VARIANT_TYPE } from "enums";

const actions = (set, get) => ({
    getSurveyTranslations: () => {
        return filter(get().translation, t => t.item !== "site" || isEmpty(t.item));
    },

    fetchTranslations: params => {
        set({ fetchingTranslations: true });
        fetchData(getTranslations(params))
            .then(res => set({ translation: res }))
            .catch(_err => {
                const setSnackbar = uiControlStore.getState().setSnackbar;
                setSnackbar(true, `Cannot fetch translations`, SNACKBAR_VARIANT_TYPE.ERROR);
            })
            .finally(() => set({ fetchingTranslations: false }));
    },

    createOneTranslation: async (translation, uniqId, item) => {
        return fetchData(createTranslation(), fitTranslationToJsonApi(translation, uniqId, item))
            .then(res => res)
            .catch(_err => {
                const setSnackbar = uiControlStore.getState().setSnackbar;
                setSnackbar(true, "Cannot create translation", SNACKBAR_VARIANT_TYPE.ERROR);
            });
    },

    editTranslation: async (id, newTranslation, language, valid = true) => {
        return fetchData(
            modifyTranslation(id),
            editTranslationToJsonApi(newTranslation, language, valid)
        )
            .then(res => res)
            .catch(_err => {
                const setSnackbar = uiControlStore.getState().setSnackbar;
                setSnackbar(true, "Cannot update translation", SNACKBAR_VARIANT_TYPE.ERROR);
            });
    }
});

export default actions;
