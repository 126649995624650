import { create } from "zustand";
import actions from "./actions";
import states from "./states";

/** WARNING : this one hasnt been correctly tested because the functionnality is deactivated */
const teamStore = create((...a) => ({
    ...states,
    ...actions(...a)
}));

export default teamStore;
