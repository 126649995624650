import React from "react";
import { Label } from "components/common";
import { PROGRESS_TYPE } from "enums";
import moment from "moment";
import { Box } from "@mui/material";
import ProgressPercentage from "./ProgressPercentage";
import ProgressDay from "./ProgressDay";

const ProgressWithDescription = props => {
    const {
        className,
        completed,
        color,
        label,
        loader_type = PROGRESS_TYPE.PERCENTAGE,
        date_start = moment(),
        date_end = moment(),
        label_day = "jour",
        no_indicator
    } = props;

    const progress = () => {
        switch (loader_type) {
            case PROGRESS_TYPE.PERCENTAGE:
                return (
                    <ProgressPercentage
                        no_indicator={no_indicator}
                        className={className}
                        color={color}
                        completed={completed}
                    />
                );
            case PROGRESS_TYPE.DAY:
                return (
                    <div>
                        <ProgressDay
                            className={className}
                            color={color}
                            date_start={date_start}
                            date_end={date_end}
                            label_day={label_day}
                        />
                    </div>
                );
            default:
                return "";
        }
    };

    return (
        <Box sx={{ textAlign: "center", letterSpacing: "0.025em" }}>
            <Label text={label} center />
            {progress()}
        </Box>
    );
};

export default ProgressWithDescription;
