import {
    CompareManagerPage,
    FormsManagerPage,
    PersonalizedReportPage,
    RelaysCountryLeadersResults,
    RightsManagerPage,
    SearchPage,
    SurveysManagerPage,
    TranslationsManagerPage,
    // CampaignsManagerPage,
    VerbatimPage
} from "components/pages";
import * as ROLES from "enums/Roles";
import * as URLS from "routes/Urls";

const ADMIN_MENU_ITEMS = [
    {
        label: "admin_topbar.item1",
        icon: "person",
        roles: [ROLES.MANAGE_PROFILES, ROLES.MANAGE_USERS],
        component: RightsManagerPage,
        url: URLS.ADMIN_USERS_MANAGEMENT
    },
    {
        label: "admin_topbar.item2",
        icon: "assignment",
        roles: [ROLES.MANAGE_FORMS],
        component: FormsManagerPage,
        url: URLS.ADMIN_FORMS_MANAGEMENT
    },
    {
        label: "admin_topbar.item3",
        icon: "bar_chart",
        roles: [ROLES.MANAGE_SURVEYS],
        component: SurveysManagerPage,
        url: URLS.ADMIN_SURVEY_URL
    },
    {
        label: "admin_topbar.item4",
        icon: "translate",
        roles: [ROLES.MANAGE_TRANSLATIONS],
        component: TranslationsManagerPage,
        url: URLS.ADMIN_TRANSLATIONS
    },
    {
        label: "admin_topbar.item5",
        icon: "show_chart",
        roles: [ROLES.MANAGE_COMPARATIF],
        component: CompareManagerPage,
        url: URLS.ADMIN_EXCEL_COMPARE
    },
    {
        label: "admin_topbar.item6",
        icon: "shuffle",
        roles: [ROLES.MANAGE_COMPILATION],
        component: PersonalizedReportPage,
        url: URLS.ADMIN_PERSONALIZED_REPORTS
    },
    {
        label: "admin_topbar.country_results_leader",
        icon: "flag",
        roles: [ROLES.MANAGE_COMPILATION],
        component: RelaysCountryLeadersResults,
        url: URLS.ADMIN_COUNTRY_LEADERS_RESULTS
    },
    {
        label: "admin_topbar.item7",
        icon: "loupe",
        roles: [ROLES.USERS_EXTRACTION, ROLES.MANAGE_USERS],
        component: SearchPage,
        url: URLS.ADMIN_SEARCH
    },
    // {
    //     label: "admin_topbar.item8",
    //     icon: "shuffle",
    //     roles: [ROLES.MANAGE_SURVEYS],
    //     component: CampaignsManagerPage,
    //     url: URLS.ADMIN_CAMPAIGNS_MANAGER
    // },
    {
        label: "admin_topbar.item9",
        icon: "comment_bank",
        roles: [ROLES.VERBATIM_REVIEWER],
        component: VerbatimPage,
        url: URLS.ADMIN_VERBATIM
    }
];

export default ADMIN_MENU_ITEMS;
