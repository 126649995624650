import { fetchData } from "../../api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { uiControlStore } from "store/store";
import {
    createTeamParameter,
    updateTeamParameter,
    getCheckTotalPerimeter,
    getCheckWhoIsInYourTeam,
    getMyTeamParameter,
    getProgN1WithTypeAndTitle,
    postUpdateMyTeamFromLdap
} from "./endpoints";

const actions = (set, get) => ({
    // Setters Redux
    setStep: value => set({ step: value }),
    setSubStep: value => set({ sub_step: value }),
    setWhoIsInYourTeam: value => set({ who_is_in_your_team: value }),
    setLoaderWhoIsInYourTeam: value => set({ loader_who_is_in_your_team: value }),
    setTotalPerimeter: value => set({ total_perimeter: value }),
    setLoaderTotalPerimeter: value => set({ loader_total_perimeter: value }),
    setProgN1MyTeam: value => set({ prog_n1_my_team: value }),
    setLoaderProgN1MyTeam: value => set({ loader_prog_n1_my_team: value }),
    setProgN1TotalPerimeter: value => set({ prog_n1_total_perimeter: value }),
    setLoaderProgN1TotalPerimeter: value => set({ loader_prog_n1_total_perimeter: value }),
    setMyTeamParameter: value => set({ my_team_parameter: value }),
    setMyTotalTeamParameter: value => set({ my_total_team_parameter: value }),
    setLoaderUpdateLdap: value => set({ loader_update_ldap: value }),

    // Actions Redux
    fetchProgN1Options: (title, type) => {
        console.log("actions teams - fetchProgN1Options");
        const setSnackbar = uiControlStore.getState().setSnackbar;

        fetchData(getProgN1WithTypeAndTitle(title, type, "search"))
            .then(res => {
                let arrayTeam = [];
                let resSize = 0;
                res.forEach(team => {
                    arrayTeam.push({ value: team.id, label: team.title });
                    resSize++;
                    if (resSize === res.length) {
                        type === "total_perimeter"
                            ? get().setProgN1TotalPerimeter(arrayTeam)
                            : get().setProgN1MyTeam(arrayTeam);
                    }
                });
            })
            .catch(() => setSnackbar(true, "Cannot find progN1 Team", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    fetchWhoIsInYourTeam: () => {
        console.log("actions teams - fetchWhoIsInYourTeam");
        const setSnackbar = uiControlStore.getState().setSnackbar;

        get().setLoaderWhoIsInYourTeam(true);
        fetchData(getCheckWhoIsInYourTeam())
            .then(res => {
                get().setLoaderWhoIsInYourTeam(false);
                get().setWhoIsInYourTeam(res);
            })
            .catch(() => setSnackbar(true, "Cannot find your team", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    fetchTotalPerimeter: () => {
        console.log("actions teams - fetchTotalPerimeter");

        const setSnackbar = uiControlStore.getState().setSnackbar;

        get().setLoaderTotalPerimeter(true);
        fetchData(getCheckTotalPerimeter())
            .then(res => {
                get().setLoaderTotalPerimeter(false);
                get().setTotalPerimeter(res);
            })
            .catch(() => setSnackbar(true, "Cannot find your team", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    updateMyTeamFromLdap: (totalPerimeter, bossUid) => {
        console.log("actions teams - updateMyTeamFromLdap");

        const setSnackbar = uiControlStore.getState().setSnackbar;

        get().setLoaderUpdateLdap(true);
        fetchData(postUpdateMyTeamFromLdap(), { managerUid: bossUid })
            .then(() => {
                setSnackbar(
                    true,
                    "Your team are updated, wait for refresh",
                    SNACKBAR_VARIANT_TYPE.SUCCESS
                );
                get().setLoaderUpdateLdap(false);
                if (totalPerimeter) {
                    get().fetchTotalPerimeter();
                } else {
                    get().fetchWhoIsInYourTeam();
                }
                get().fetchMyTeamParameter(bossUid, "total_perimeter");
                get().fetchMyTeamParameter(bossUid, "direct_team");
            })
            .catch(() => setSnackbar(true, "Cannot update your team", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    fetchMyTeamParameter: (bossUid, type) => {
        console.log("actions teams - fetchMyTeamParameter");
        const setSnackbar = uiControlStore.getState().setSnackbar;

        fetchData(getMyTeamParameter(bossUid, type))
            .then(res => {
                if (0 in res) {
                    type === "total_perimeter"
                        ? get().setMyTotalTeamParameter(res[0])
                        : get().setMyTeamParameter(res[0]);
                } else {
                    type === "total_perimeter"
                        ? get().setMyTotalTeamParameter(res)
                        : get().setMyTeamParameter(res);
                }
            })
            .catch(() => setSnackbar(true, "Cannot find your team", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    fetchCreateTeamParameter: (myTeam, myTotalTeam) => {
        console.log("actions teams - fetchCreateTeamParameter");

        const setSnackbar = uiControlStore.getState().setSnackbar;

        setSnackbar(true, "Please wait...", SNACKBAR_VARIANT_TYPE.WARNING);
        if (myTotalTeam != null) {
            if (myTotalTeam.hasOwnProperty("id")) {
                fetchData(updateTeamParameter(myTotalTeam.id), myTotalTeam)
                    .then(res => {})
                    .catch(err => {
                        setSnackbar(
                            true,
                            "Cannot create your total team, contact the support",
                            SNACKBAR_VARIANT_TYPE.ERROR
                        );
                    });
            } else {
                fetchData(createTeamParameter(), myTotalTeam)
                    .then(res => {})
                    .catch(err => {
                        console.log(err);
                        setSnackbar(
                            true,
                            "Cannot create your total team, contact the support",
                            SNACKBAR_VARIANT_TYPE.ERROR
                        );
                    });
            }
        }
        if (myTeam.hasOwnProperty("id")) {
            fetchData(updateTeamParameter(myTeam.id), myTeam)
                .then(res => {
                    setSnackbar(true, "Team created !", SNACKBAR_VARIANT_TYPE.SUCCESS);
                    // TODO - replace dispatch(push(TEAM_GREATING_URL));
                })
                .catch(err => {
                    setSnackbar(
                        true,
                        "Cannot create your team, contact the support",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    );
                });
        } else {
            fetchData(createTeamParameter(), myTeam)
                .then(res => {
                    setSnackbar(true, "Team created !", SNACKBAR_VARIANT_TYPE.SUCCESS);
                    // TODO - replace dispatch(push(TEAM_GREATING_URL));
                })
                .catch(err => {
                    console.log(err);
                    setSnackbar(
                        true,
                        "Cannot create your team, contact the support",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    );
                });
        }
    }
});

export default actions;
