export const reinitializeTranslation = translations => {
    const keys = Object.keys(translations.content);
    keys.map(key => (translations.content[key].valid = false));
    return translations;
};

export const editTranslationToJsonApi = (value, selectedLanguage, valid) => ({
    content: {
        [selectedLanguage]: {
            value: value,
            valid: valid
        }
    }
});

export const fitTranslationToJsonApi = (value = "", uniqId = "", item = "") => ({
    uniqId: uniqId + "." + Math.random(),
    content: {
        fr: {
            value: value,
            valid: false
        },
        en: {
            value: value,
            valid: true
        }
    },
    item
});
