import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";

const PREFIX = "UploadButton";

const classes = {
    container: `${PREFIX}Container`,
    button: `${PREFIX}Button`,
    chip: `${PREFIX}Chip`,
    input: `${PREFIX}Input`
};

const Root = styled("div")(({ theme }) => ({
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    [`& .${classes.button}`]: {
        border: "none",
        borderRadius: theme.spacing(1),
        color: theme.palette.common["blue"],
        boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 1px 5px rgba(0,0,0,0.10)"
    },

    [`& .${classes.chip}`]: {
        width: "fit-content",
        color: "white",
        backgroundColor: theme.palette.common["blue"],
        marginTop: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto"
    },

    [`& .${classes.input}`]: {
        display: "none"
    }
}));

const UploadButton = props => {
    const { className, label, onChange, accept = "image/*" } = props;

    const random = Math.random();

    return (
        <Root>
            <input
                accept={accept}
                className={classes.input}
                id={label + random}
                multiple
                type="file"
                onChange={onChange}
            />
            <label htmlFor={label + random}>
                {/* <Button component="span" className={`${className} ${classes.button}`} fullWidth> */}
                <Button
                    component="span"
                    variant="contained"
                    color="primary"
                    startIcon={<PublishIcon />}
                    className={className}>
                    {label}
                </Button>
            </label>
        </Root>
    );
};
export default UploadButton;
