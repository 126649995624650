import { find } from "lodash";
import userProfileStore from "store/user";

const useAuthorization = () => {
    const myProfile = userProfileStore(state => state.myProfile);

    /**
     * Check if the current user is allowed
     * @param {*} role
     * @returns boolean
     */
    const can = role => Boolean(find(myProfile?.profile?.roles, { name: role }));

    return { can };
};

export default useAuthorization;
