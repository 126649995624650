import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { getCountries } from "store/countries/endpoints";
import { compare, convertCountryCode } from "store/countries/utils";
import { uiControlStore } from "store/store";

const actions = (set, get) => ({
    setCurrentCountry: country => {
        set({ currentCountry: country });
    },

    fetchCountries: async () => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(getCountries())
            .then(res =>
                set({
                    countries: [
                        ...res[0]["options"]
                            .map(country => ({
                                ...country,
                                label: convertCountryCode(country.label)
                            }))
                            .sort(compare)
                    ]
                })
            )
            .catch(() => setSnackbar(true, "Cannot fetch countries", SNACKBAR_VARIANT_TYPE.ERROR));
    }
});
export default actions;
