import * as TYPES from "./types";

const initalState = {
    surveys: []
};

const surveysManager = (state = initalState, action) => {
    switch (action.type) {
        case TYPES.SET_SURVEYS: {
            return {
                ...state,
                surveys: [...action.payload]
            };
        }
        case TYPES.SET_STARTED_SURVEYS: {
            return {
                ...state,
                started: [...action.payload]
            };
        }
        case TYPES.SET_EDITING_SURVEYS: {
            return {
                ...state,
                editing: [...action.payload]
            };
        }
        case TYPES.SET_FINISHED_SURVEYS: {
            return {
                ...state,
                completed: [...action.payload]
            };
        }
        default:
            return state;
    }
};

export default surveysManager;
