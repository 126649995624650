import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { Divider } from "@mui/material";

const PREFIX = "Breadcrumb";

const classes = {
    root: `${PREFIX}root`,
    paper: `${PREFIX}paper`,
    link: `${PREFIX}link`,
    activeLink: `${PREFIX}activeLink`
};

const Root = styled("div")(({ theme }) => ({
    justifyContent: "center",
    flexWrap: "wrap",
    zIndex: theme.zIndex.drawer + 1,
    top: theme.mixins.toolbar,
    [`& .${classes.paper}`]: {
        padding: theme.spacing(1, 9)
    },

    [`& .${classes.link}`]: {
        fontStyle: "italic",
        textDecoration: "none",
        color: theme.palette.common["grey"]
    },

    [`& .${classes.activeLink}`]: {
        fontStyle: "italic",
        textDecoration: "none",
        color: theme.palette.common["admin_grey"]
    }
}));

const Breadcrumb = () => {
    const location = useLocation();
    let path = "";

    const splittedPath = location.pathname.split("/").filter(route => route !== "");

    const links = splittedPath.map((route, index) => {
        path += "/" + route;
        return (
            <Link
                key={index}
                to={path}
                className={splittedPath.length - 1 !== index ? classes.link : classes.activeLink}>
                {route.toUpperCase()}
            </Link>
        );
    });

    const breadcrumb_value = "Accueil".toUpperCase();
    return (
        <Root>
            <Paper elevation={0} className={classes.paper}>
                <Breadcrumbs separator="›" aria-label="Breadcrumb">
                    <Link to="/" className={classes.link}>
                        {breadcrumb_value}
                    </Link>
                    {links}
                </Breadcrumbs>
            </Paper>
            <Divider />
        </Root>
    );
};

export default Breadcrumb;
