export const getCategories = () => ({
    method: "GET",
    suffix: "/categories"
});

export const createCategories = () => ({
    method: "POST",
    suffix: "/categories"
});

export const modifyCategories = id => ({
    method: "PUT",
    suffix: `/categories/${id}`
});
