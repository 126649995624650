import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const PREFIX = "InputNumber";

const classes = {
    root: `${PREFIX}Root`,
    input: `${PREFIX}Input`
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    backgroundColor: "white",
    boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 1px 5px rgba(0,0,0,0.10)",
    borderRadius: theme.spacing(1),
    padding: "5px 10px",
    color: theme.palette.common["blue"],
    [`& .${classes.input}`]: {
        "&::placeholder": {
            color: "black"
        }
    }
}));

const InputNumber = props => {
    const { id, required, placeholder = "min", onChange = null, value = "" } = props;
    return (
        <StyledInputBase
            id={id}
            onChange={onChange}
            value={value}
            required={required}
            classes={{ input: classes.input }}
            placeholder={placeholder}
            type="number"
            fullWidth
            inputProps={{ min: "1", max: "60" }}
        />
    );
};

export default InputNumber;
