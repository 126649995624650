const prefix = "Campaigns";
export const SET_FLAG_DUPLICATE = `${prefix}/SET_FLAG_DUPLICATE`;
export const SET_FLAG_MODIFICATION = `${prefix}/SET_FLAG_MODIFICATION`;
export const SET_CAMPAIGN_PAGE_MODE = `${prefix}/SET_CAMPAIGN_PAGE_MODE`;
export const SET_CAMPAIGNS = `${prefix}/SET_CAMPAIGNS`;
export const RESET_CAMPAIGN = `${prefix}/RESET_CAMPAIGN`;
export const SET_CAMPAIGN = `${prefix}/SET_CAMPAIGN`;
export const SET_CAMPAIGN_EMAIL_FILES = `${prefix}/SET_CAMPAIGN_EMAIL_FILES`;
export const RESET_CAMPAIGN_EDITING = `${prefix}/RESET_CAMPAIGN_EDITING`;
export const SET_CAMPAIGN_EDITING = `${prefix}/SET_CAMPAIGN_EDITING`;
export const SET_CAMPAIGN_EDITING_EMAIL_FILES = `${prefix}/SET_CAMPAIGN_EMAIL_FILES`;
