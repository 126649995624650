import React from "react";
import { Tooltip, Typography } from "@mui/material";

const ComponentWithTooltip = props => {
    const { children, title, placement = "top", className } = props;

    const tooltipTitle = <Typography sx={{ fontSize: "1rem", color: "white" }}>{title}</Typography>;

    const ChildrenComponent = React.forwardRef((props, ref) => {
        return (
            <div {...props} ref={ref}>
                {children}
            </div>
        );
    });

    return (
        <Tooltip title={tooltipTitle} placement={placement} arrow className={className}>
            <ChildrenComponent />
        </Tooltip>
    );
};

export default ComponentWithTooltip;
