import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledTypography = styled(Typography)(({ theme, styled }) => {
    const base_style = {
        color: theme.palette.common[styled.color],
        textAlign: `${styled.center ? "center" : ""}`
    };
    switch (styled.variant) {
        case "admin_menuitem":
            return {
                ...base_style,
                fontSize: "1rem",
                fontWeight: "bold",
                fontStyle: "italic"
            };
        case "triple_cta_item":
            return {
                ...base_style,
                fontSize: "2rem",
                fontWeight: "bold",
                fontStyle: "italic",
                color: "white",
                textShadow: "black 0px 0px 10px"
            };
        default:
            return {
                ...base_style
            };
    }
});

const Label = props => {
    const { text = "", center, variant, color = "black", className = "" } = props;
    return (
        <StyledTypography
            className={className}
            styled={{ variant: variant, color: color, center: center }}>
            {text.toUpperCase()}
        </StyledTypography>
    );
};

export default Label;
