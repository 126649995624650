import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";

const PREFIX = "TabsContainer";

const classes = {
    centered: `${PREFIX}Centered`,
    TabsWrapper: `${PREFIX}TabsWrapper`,
    rootTabs: `${PREFIX}RootTabs`,
    rootTab: `${PREFIX}RootTab`
};

const Root = styled("div")(({ _theme }) => ({
    [`& .${classes.centered}`]: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    [`& .${classes.TabsWrapper}`]: {
        margin: "25px 50px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-evenly",
        minWidth: "500px",
        maxWidth: "750px"
    }
}));

const CustomTabPanel = ({ children, value, index, ...other }) => {
    return (
        <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

/**
 * @deprecated  When refactoring a feature using TabsContainer, use TopNavContainer instead (uses react router)
 */
const TabsContainer = ({ tabs, children }) => {
    const childrenArray = React.Children.toArray(children);

    const initAvailableTab = tabs => {
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].authorized) return i;
        }
        return 0;
    };
    const [value, setValue] = useState(initAvailableTab(tabs));

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <Root>
            <div className={classes.centered}>
                <Paper className={classes.TabsWrapper} variant="outlined">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        sx={{
                            width: "100%",
                            borderRadius: "10px"
                        }}>
                        {tabs.map((tab, index) => (
                            <Tab
                                sx={{ fontWeight: "600", flexGrow: 1 }}
                                key={index}
                                label={tab.label}
                                classes={{ root: classes.rootTab }}
                                disabled={!tab.authorized}
                            />
                        ))}
                    </Tabs>
                </Paper>
            </div>
            {childrenArray.map((child, index) => {
                return (
                    <CustomTabPanel value={value} index={index}>
                        {child}
                    </CustomTabPanel>
                );
            })}
        </Root>
    );
};

export default TabsContainer;
