import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "Title";

const classes = {
    root: `${PREFIX}Root`,
    underline: `${PREFIX}Underline`
};

const StyledTypography = styled(Typography)(({ theme, styled }) => {
    const base_style = {
        color: theme.palette.common[`${styled.color}`],
        whiteSpace: "normal"
    };
    const getTypoStyle = () => {
        switch (styled.variant) {
            case "title":
                return {
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    ...base_style
                };
            case "subtitle":
                return {
                    fontSize: "1.1rem",
                    fontWeight: "normal",
                    fontStyle: "italic",
                    ...base_style
                };
            case "header_title":
                return {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    ...base_style
                };
            default:
                return {
                    ...base_style
                };
        }
    };

    return {
        ...getTypoStyle(),
        [`& .${classes.underline}`]: {
            textAlign: "center",
            "&::after": {
                display: "block",
                margin: "auto",
                height: "3px",
                width: "50px",
                background: theme.palette.common["green"],
                content: "''"
            }
        }
    };
});

const Title = props => {
    const { text = "", variant = "title", color = "black", className, underline = false } = props;
    return (
        <StyledTypography
            styled={{ variant: variant, color: color }}
            className={`${className} ${underline ? classes.underline : ""}`}>
            {text.toUpperCase()}
        </StyledTypography>
    );
};

export default Title;
