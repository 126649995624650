import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { Radar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from "chart.js";

const PREFIX = "ResultsRadar";

const classes = {
    radar: `${PREFIX}Radar`,
    dangerPoint: `${PREFIX}DangerPoint`,
    competitivenessPoint: `${PREFIX}CompetitivenessPoint`,
    differentiationPoint: `${PREFIX}DifferentiationPoint`
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.radar}`]: {
        padding: "25px 50px 0 50px",
        height: "250px",
        [theme.breakpoints.down("lg")]: {
            padding: "25px 25px 0 25px"
        }
    },

    [`& .${classes.dangerPoint}`]: {
        height: "6px",
        width: "6px",
        border: "2px solid",
        borderRadius: "25px",
        backgroundColor: "rgba(255, 108, 64, .3)",
        borderColor: "#FF6C40",
        margin: "5px"
    },

    [`& .${classes.competitivenessPoint}`]: {
        height: "6px",
        width: "6px",
        border: "2px solid",
        borderRadius: "25px",
        borderColor: "#2D99FF",
        backgroundColor: "rgba(45, 153, 255, .3)",
        margin: "5px"
    },

    [`& .${classes.differentiationPoint}`]: {
        height: "6px",
        width: "6px",
        border: "2px solid",
        borderRadius: "25px",
        borderColor: "#00AB55",
        backgroundColor: "rgba(0, 171, 85, .3)",
        margin: "5px"
    }
}));

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const CONFIG = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        r: {
            grid: {
                circular: true
            },
            beginAtZero: true,
            ticks: {
                display: false,
                beginAtZero: true,
                max: 100,
                min: 0,
                stepSize: 20
            }
        }
    },
    borderWidth: 1
};

const ResultsRadar = props => {
    const { radarData, breakpoint } = props;

    const { t } = useTranslation();

    const legendDangerZone = t("myresults_widget.legend_dangerzone");
    const legendCompetitivenessZone = t("myresults_widget.legend_competitivenesszone");
    const legendDifferenciationZone = t("myresults_widget.legend_differientiationzone");
    return (
        <Root>
            <div className={classes.radar}>
                <Radar options={CONFIG} data={radarData} />
            </div>
            <hr />
            <Grid container>
                <Grid item xs={12} md={4} container wrap="nowrap">
                    <Grid item>
                        <div className={classes.dangerPoint}></div>
                    </Grid>
                    <Grid item container direction={breakpoint ? "row" : "column"}>
                        <Typography>0% - 50%</Typography>
                        <Typography style={{ margin: `0 ${breakpoint ? "5px" : "0"}` }}>
                            {legendDangerZone}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} container wrap="nowrap">
                    <Grid item>
                        <div className={classes.competitivenessPoint}></div>
                    </Grid>
                    <Grid item container direction={breakpoint ? "row" : "column"}>
                        <Typography>50% - 75%</Typography>
                        {breakpoint && <span style={{ margin: "0 5px" }}></span>}
                        <Typography style={{ margin: `0 ${breakpoint ? "5px" : "0"}` }}>
                            {legendCompetitivenessZone}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} container wrap="nowrap">
                    <Grid item>
                        <div className={classes.differentiationPoint}></div>
                    </Grid>
                    <Grid item container direction={breakpoint ? "row" : "column"}>
                        <Typography>75% - 100%</Typography>
                        <Typography style={{ margin: `0 ${breakpoint ? "5px" : "0"}` }}>
                            {legendDifferenciationZone}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );
};

export default ResultsRadar;
