const myResultsTab = "admin.results.tab_me";
const myTeamResultsTab = "admin.results.tab_my_team";
const myDirectTeamResultsTab = "admin.results.tab_my_direct_subordinates";
const myTeamsPerimetersResultsTab = "admin.results.tab_all_my_subordinates";
const myCountryResultsTab = "admin.results.tab_country";

const MY_RESULTS = { value: 0, label: myResultsTab };
const MY_TEAM_RESULTS = { value: 1, label: myTeamResultsTab };
const MY_DIRECT_TEAM_RESULTS = { value: 2, label: myDirectTeamResultsTab };
const MY_TEAMS_PERIMETERS_RESULTS = { value: 3, label: myTeamsPerimetersResultsTab };
const MY_COUNTRY_RESULTS = { value: 4, label: myCountryResultsTab };

export {
    MY_RESULTS,
    MY_TEAM_RESULTS,
    MY_DIRECT_TEAM_RESULTS,
    MY_TEAMS_PERIMETERS_RESULTS,
    MY_COUNTRY_RESULTS,
};
