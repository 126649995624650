export const getQuestions = () => ({
    method: "GET",
    suffix: "/questions"
});

export const createQuestions = () => ({
    method: "POST",
    suffix: "/questions"
});

export const modifyQuestions = id => ({
    method: "PUT",
    suffix: `/questions/${id}`
});