import { fetchData } from "api/fetch";
import { createQuestions, getQuestions, modifyQuestions } from "./endpoints";
import { translationsStore, uiControlStore, tagsStore } from "store/store";
import { formatQuestionForJsonApi } from "./formatters";
import { SNACKBAR_VARIANT_TYPE } from "enums";

const actions = (set, get) => ({
    commonQuestionsSelector: () => {
        const questions = get().questions;
        return questions.filter(question => !question.isLocal);
    },

    localQuestionsSelector: () => {
        const questions = get().questions;
        return questions.filter(question => question.isLocal);
    },

    editQuestion: question => {
        const id = question.id;
        set(state => ({
            questions: [...state.questions.map(q => (q.id == id ? { ...question } : { ...q }))]
        }));
    },

    fetchQuestions: () => {
        fetchData(getQuestions()).then(res => {
            set({ questions: res });
        });
    },

    createQuestion: async (question, isLocal) => {
        const createTagThunk = tagsStore.getState().createTagThunk;
        const createOneTranslation = translationsStore.getState().createOneTranslation;
        const setSnackbar = uiControlStore.getState().setSnackbar;

        const {
            create_question_name,
            create_question_help,
            create_question_tags,
            create_question_custom_label,
            create_question_priority
        } = question;

        question["create_question_isLocal"] = isLocal;

        const question_name = await createOneTranslation(
            create_question_name,
            "question.name",
            "survey"
        );

        const question_help = await createOneTranslation(
            create_question_help,
            "question.help",
            "survey"
        );

        const custom_label = await createOneTranslation(
            create_question_custom_label,
            "question.custom",
            "survey"
        );

        let tagsId = [];
        if (create_question_tags != null) {
            tagsId = create_question_tags.map(async tag => {
                if (tag.value === "TO_CREATE") {
                    const tagCreated = await createTagThunk(tag.label);
                    tag.value = tagCreated["@id"];
                }
                return tag.value;
            });

            tagsId = await Promise.all(tagsId);
        }

        return fetchData(
            createQuestions(),
            formatQuestionForJsonApi(
                question,
                question_name,
                question_help,
                tagsId,
                custom_label,
                create_question_priority
            )
        )
            .then(res => {
                set(state => ({
                    questions: [...state.questions, res]
                }));
            })
            .catch(() => setSnackbar(true, "Cannot create creation", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    toggleQuestionActivation: async ({ id, isActive }) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(modifyQuestions(id), { isActive: !isActive })
            .then(res => {
                set(state => ({
                    questions: [
                        ...state.questions.map(question =>
                            question.id == res.id
                                ? { ...question, id: res.id, isActive: res.isActive }
                                : { ...question }
                        )
                    ]
                }));
            })
            .catch(() =>
                setSnackbar(true, "Cannot toggle activation", SNACKBAR_VARIANT_TYPE.ERROR)
            );
    },

    updateQuestion: async (initial_values, new_values) => {
        const createTagThunk = tagsStore.getState().createTagThunk;

        const setSnackbar = uiControlStore.getState().setSnackbar;
        const createOneTranslation = translationsStore.getState().createOneTranslation;
        const editTranslation = translationsStore.getState().editTranslation;

        const { id, content, help, customLabel } = initial_values;
        const {
            create_question_name,
            create_question_help,
            create_question_tags,
            create_question_custom_label,
            create_question_priority
        } = new_values;

        const question_name = await editTranslation(content.id, create_question_name, "en");

        let custom_label = null;
        if (custom_label !== null) {
            custom_label = await editTranslation(
                customLabel.id,
                create_question_custom_label,
                "en"
            );
        } else {
            custom_label = await createOneTranslation(
                create_question_custom_label,
                "question.custom",
                "survey"
            );
        }

        let question_help = null;
        if (question_help !== null) {
            question_help = await editTranslation(help.id, create_question_help, "en");
        } else {
            question_help = await createOneTranslation(
                create_question_help,
                "question.help",
                "survey"
            );
        }

        let tagsId = null;
        if (create_question_tags !== null) {
            tagsId = create_question_tags.map(async tag => {
                if (tag.value === "TO_CREATE") {
                    const tagCreated = await createTagThunk(tag.label);
                    return tagCreated["@id"];
                }
                return tag.value;
            });
            tagsId = await Promise.all(tagsId);
        }

        return fetchData(
            modifyQuestions(id),
            formatQuestionForJsonApi(
                new_values,
                question_name,
                question_help,
                tagsId,
                custom_label,
                create_question_priority
            )
        )
            .then(res => {
                get().editQuestion(res);
            })
            .catch(() => setSnackbar(true, "Cannot update question", SNACKBAR_VARIANT_TYPE.ERROR));
    }
});
export default actions;
