import React from "react";
import { styled } from "@mui/material/styles";
import { LeftBar } from "components/common";

const PREFIX = "ContentWithLeftBar";

const classes = {
    content: `${PREFIX}Content`,
    toolbar: `${PREFIX}Toolbar`
};

const Root = styled("div")(({ theme }) => ({
    display: "flex",
    height: "100%",
    [`& .${classes.content}`]: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default
    },

    //cheat pour décaler le contenu vers le bas
    [`& .${classes.toolbar}`]: theme.mixins.toolbar
}));

/**
 * @deprecated Use ContentLeftDrawer instead
 */
const ContentWithLeftBar = props => {
    const { leftBarContent, children } = props;

    return (
        <Root>
            <LeftBar>
                <div className={classes.toolbar} />
                {leftBarContent}
            </LeftBar>
            <main className={classes.content}>{children}</main>
        </Root>
    );
};

export default ContentWithLeftBar;
