const states = {
    onGoing: [],
    answered: [],
    notAnswered: [],
    surveysList: [],
    editingSurveyId: null,
    editingSurvey: {},
    editingSurveyLoader: false,
    surveyToFill: {},
    step: 0,
    subStep: 0,
    currentLeader: "",
    editingSurveyLeaders: []
};

export default states;
