const RESULT = "RESULT";
const MY_RESULTS = "MY_RESULTS";
const MANAGE_USERS = "MANAGE_USERS";
const MANAGE_FORMS = "MANAGE_FORMS";
const MANAGE_SURVEYS = "MANAGE_SURVEYS";
const MANAGE_TRANSLATIONS = "MANAGE_TRANSLATIONS";
const MANAGE_COMPILATION = "MANAGE_COMPILATION";
const MANAGE_COMPARATIF = "MANAGE_COMPARATIF";
const SURVEY_CALENDAR = "SURVEY_CALENDAR";
const SURVEY_STATS = "SURVEY_STATS";
const MANAGE_PROFILES = "MANAGE_PROFILES";
const MANAGE_ASSIGNEMENTS = "MANAGE_ASSIGNEMENTS";
const MANAGE_UNITED_FORM = "MANAGE_UNITED_FORM";
const MANAGE_LOCAL_FORM = "MANAGE_LOCAL_FORM";
const MANAGE_SEARCH = "MANAGE_SEARCH";
const USERS_EXTRACTION = "USERS_EXTRACTION";
const SURVEY_CREATE = "SURVEY_CREATE";
const SURVEY_LAUNCH = "SURVEY_LAUNCH";
const SURVEY_STOP = "SURVEY_STOP";
const SURVEY_MODIFY = "SURVEY_MODIFY";
const SURVEY_VIEW = "SURVEY_VIEW";
const SURVEY_RELAUNCH = "SURVEY_RELAUNCH";
const SURVEY_DUPLICATE = "SURVEY_DUPLICATE";
const TRANSLATION_VALIDATE = "TRANSLATION_VALIDATE";
const TRANSLATION_MODIFY = "TRANSLATION_MODIFY";
const COMPILATION_UNITED_ACCESS = "COMPILATION_UNITED_ACCESS";
const COMPILATION_DISABLE_PROD_RULES = "COMPILATION_DISABLE_PROD_RULES";
const VERBATIM_REVIEWER = "VERBATIM_REVIEWER";
const RESULT_RELAY = "RESULT_RELAY";
const ADMIN = "ADMIN";

export {
    ADMIN,
    COMPILATION_UNITED_ACCESS,
    COMPILATION_DISABLE_PROD_RULES,
    MANAGE_ASSIGNEMENTS,
    MANAGE_COMPARATIF,
    MANAGE_COMPILATION,
    MANAGE_FORMS,
    MANAGE_LOCAL_FORM,
    MANAGE_PROFILES,
    MANAGE_SEARCH,
    MANAGE_SURVEYS,
    MANAGE_TRANSLATIONS,
    MANAGE_UNITED_FORM,
    MANAGE_USERS,
    MY_RESULTS,
    RESULT,
    RESULT_RELAY,
    SURVEY_CALENDAR,
    SURVEY_CREATE,
    SURVEY_DUPLICATE,
    SURVEY_LAUNCH,
    SURVEY_MODIFY,
    SURVEY_RELAUNCH,
    SURVEY_STATS,
    SURVEY_STOP,
    SURVEY_VIEW,
    TRANSLATION_MODIFY,
    TRANSLATION_VALIDATE,
    USERS_EXTRACTION,
    VERBATIM_REVIEWER
};
