import { CAMPAIGN_PAGE_MODE, CAMPAIGN_STATE } from "enums";
import * as TYPES from "./types";

// campaign property is use to store and manipulate the campaign object
// whereas campaignEditing property is use to store the initial campaign object which will be editing (modification will be store in the the campaign property).
const stateInitial = {
    flagDuplicate: false,
    flagModification: false,
    campaigns: [],
    campaignPageMode: CAMPAIGN_PAGE_MODE.LIST,
    campaign: {
        title: "",
        dateOfDispatch: "",
        state: CAMPAIGN_STATE.BEING_CREATED,
        survey: {},
        countries: [],
        generatedQuestion: {},
        answerType: null,
        branch: [],
        service: [],
        files: []
    },
    campaignEditing: {
        title: "",
        dateOfDispatch: "",
        state: CAMPAIGN_STATE.BEING_CREATED,
        survey: {},
        countries: [],
        generatedQuestion: {},
        answerType: null,
        branch: [],
        service: [],
        files: []
    }
};

const campaigns = (state = stateInitial, action) => {
    switch (action.type) {
        case TYPES.SET_FLAG_DUPLICATE:
            return {
                ...state,
                flagDuplicate: action.payload
            };
        case TYPES.SET_FLAG_MODIFICATION:
            return {
                ...state,
                flagModification: action.payload
            };
        case TYPES.RESET_CAMPAIGN:
            return {
                ...state,
                campaigns: stateInitial.campaign
            };
        case TYPES.RESET_CAMPAIGN_EDITING:
            return {
                ...state,
                campaignEditing: stateInitial.campaignEditing
            };
        case TYPES.SET_CAMPAIGNS:
            return {
                ...state,
                campaigns: [...action.payload]
            };
        case TYPES.SET_CAMPAIGN: {
            return {
                ...state,
                campaign: { ...state.campaign, ...action.payload }
            };
        }
        case TYPES.SET_CAMPAIGN_EDITING: {
            return {
                ...state,
                campaignEditing: { ...state.campaignEditing, ...action.payload }
            };
        }
        case TYPES.SET_CAMPAIGN_EMAIL_FILES: {
            return {
                ...state,
                campaign: { ...state.campaign, files: [...action.payload] }
            };
        }
        case TYPES.SET_CAMPAIGN_EDITING_EMAIL_FILES: {
            return {
                ...state,
                campaignEditing: { ...state.campaignEditing, files: [...action.payload] }
            };
        }
        case TYPES.SET_CAMPAIGN_PAGE_MODE:
            return { ...state, campaignPageMode: action.payload };
        default:
            return state;
    }
};

export default campaigns;
