import React, { Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";

const withSuspense = Component => props => {
    const fallbackComponent = (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <CircularProgress size={24} />
        </Box>
    );

    return (
        <Suspense fallback={fallbackComponent}>
            <Component {...props} />
        </Suspense>
    );
};

export default withSuspense;
