import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Container } from "@mui/material";

const DRAWER_WIDTH = 240;

const openedMixin = theme => ({
    top: "unset",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
});

const closedMixin = theme => ({
    top: "unset",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "open" })(
    ({ theme, open }) => ({
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme)
        })
    })
);

const ContentLeftDrawer = props => {
    const { drawerMenu, children } = props;

    const [open, setOpen] = useState(true);
    const handleOpen = () => {
        setOpen(open => !open);
    };

    return (
        <Box sx={{ display: "flex", minHeight: "100%", flex: "1" }}>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
                }}
                PaperProps={{
                    sx: {
                        position: "unset"
                    }
                }}>
                <DrawerHeader>
                    <IconButton onClick={handleOpen}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                {drawerMenu.map(menuGroup => (
                    <>
                        <Divider />
                        <List>
                            {menuGroup.map((menuItem, index) => (
                                <ListItem
                                    key={menuItem.text + index}
                                    disablePadding
                                    sx={{ display: "block" }}>
                                    <ListItemButton
                                        disabled={menuItem.disabled}
                                        onClick={menuItem.onClick}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? "initial" : "center",
                                            px: 2.5
                                        }}>
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "auto",
                                                justifyContent: "center"
                                            }}>
                                            {menuItem.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={menuItem.text}
                                            sx={{ opacity: open ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </>
                ))}
            </Drawer>
            <Box sx={{ flexGrow: 1, m: "34px" }}>
                <Container maxWidth="xl">{children}</Container>
            </Box>
        </Box>
    );
};

export default ContentLeftDrawer;
