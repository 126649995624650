import React from "react";

import { styled } from "@mui/material/styles";

import CreatableSelect from "react-select/creatable";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

const PREFIX = "AutoCompleteWithCreation";

const classes = {
    chip: `${PREFIX}Chip`,
    chipIcon: `${PREFIX}ChipIcon`,
    chipFocused: `${PREFIX}ChipFocused`
};

const StyledCreatableSelect = styled(CreatableSelect)(({ theme }) => ({
    [`& .${classes.chip}`]: {
        fontSize: 10,
        margin: theme.spacing(0.5, 0.25),
        backgroundColor: theme.palette.common["blue"],
        color: "white"
    },

    [`& .${classes.chipIcon}`]: {
        color: "white"
    },

    [`& .${classes.chipFocused}`]: {
        backgroundColor: "red"
    }
}));

const createOption = label => ({
    label: label.toUpperCase(),
    value: "TO_CREATE"
});

const MultiValue = props => {
    return (
        <Chip
            tabIndex={-1}
            label={<Typography variant={"button"}>{props.children}</Typography>}
            className={`${classes.chip}	${classes.chipFocused ? props.isFocused : ""}`}
            onDelete={() => handleDelete(props, props.children)}
            deleteIcon={<CancelIcon className={classes.chipIcon} {...props.removeProps} />}
        />
    );
};

const components = {
    MultiValue
};

const handleDelete = (props, optionDelete) => {
    const newOptions = props.selectProps.value.filter(option => option.label !== optionDelete);
    props.setValue(newOptions);
};

const AutoCompleteWithCreation = props => {
    const theme = useTheme();
    const style = {
        container: provided => ({
            ...provided,
            fontFamily: "Roboto Condensed",
            boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 1px 5px rgba(0,0,0,0.10)",
            borderRadius: theme.spacing(1),
            color: theme.palette.common["blue"],
            outline: "none",
            width: "200px"
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: theme.spacing(1),
            padding: "5px 5px",
            border: 0,
            boxShadow: 0
        }),
        menu: provided => ({
            ...provided,
            borderRadius: theme.spacing(0),
            textAlign: "left",
            color: "black"
        }),
        option: (provided, state) => ({
            ...provided,
            padding: "10px 16px",
            backgroundColor: state.isFocused || state.isActive ? "lightgrey" : "white"
        })
    };

    const {
        onDelete,
        options,
        setOptions,
        is_multi,
        is_disabled,
        value,
        onChange,
        placeholder
    } = props;

    const handleCreate = inputValue => {
        const newOption = createOption(inputValue);
        setOptions([...options, newOption]);
        onChange([...value, newOption]);
    };

    return (
        <StyledCreatableSelect
            styles={style}
            isClearable
            isDisabled={is_disabled}
            onChange={onChange}
            onCreateOption={handleCreate}
            options={options}
            value={value}
            isMulti={is_multi}
            components={components}
            onDelete={onDelete}
            placeholder={placeholder}
        />
    );
};

export default AutoCompleteWithCreation;
