import { SURVEY_STATE } from "enums";

export const startedSurveysSelector = state =>
    state.surveysManager.surveys.filter(s => s.status === SURVEY_STATE.STARTED);

export const editingSurveysSelector = state =>
    state.surveysManager.surveys.filter(s => s.status === SURVEY_STATE.STAND_BY);

export const finishedSurveysSelector = state =>
    state.surveysManager.surveys.filter(s => s.status === SURVEY_STATE.ENDED);

export const surveySelector = id => state => state.surveysManager.surveys.find(s => s.id === id);

export const targetsInProgressSelector = id => state =>
    state.surveysManager.surveys.find(s => s.id === id)?.targetInProgress || [];

export const targetsCompletedSelector = id => state =>
    state.surveysManager.surveys.find(s => s.id === id)?.targetCompleted || [];

export const accessResultsCountriesSelector = id => state =>
    state.surveysManager.surveys.find(s => s.id === id)?.accessResultCountries || [];
