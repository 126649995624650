import React, { useState } from "react";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect } from "react";

const calcPercentageDone = (startDate, endDate) => {
    const percentage =
        (100 * moment().diff(startDate, "days")) / endDate.diff(startDate, "days") + 1;
    // +1 correspond à ajd'hui
    return percentage < 100 ? percentage : 100;
};

const ProgressDay = props => {
    const { date_start, date_end } = props;

    const [value, setValue] = useState(calcPercentageDone(date_start, date_end));
    useEffect(() => {
        setValue(calcPercentageDone(date_start, date_end));
    }, [date_start, date_end]);

    return (
        <LinearProgress sx={{ height: 15, bgcolor: "white" }} variant="determinate" value={value} />
    );
};
export default ProgressDay;
