import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PREFIX = "TextButton";

const classes = {
    root: `${PREFIX}Root`,
    leftIcon: `${PREFIX}LeftIcon`,
    rightIcon: `${PREFIX}RightIcon`
};

const StyledButton = styled(Button)(({ theme, style }) => ({
    borderColor: theme.palette.common[style.color],
    border: `1px solid ${theme.palette.common[style.color]} inset`,
    color: theme.palette.common[style.color],
    fontWeight: "bold",

    [`& .${classes.leftIcon}`]: {
        fontSize: 20,
        marginRight: theme.spacing(1)
    },

    [`& .${classes.rightIcon}`]: {
        fontSize: 20,
        marginLeft: theme.spacing(1)
    }
}));

const TextButton = props => {
    const { color, label, LeftIcon, RightIcon, className, onClick, disabled } = props;

    return (
        <StyledButton
            style={{ color: color }}
            onClick={onClick}
            className={`${classes.root} ${className}`}
            disabled={disabled}
            variant="text">
            {LeftIcon && <LeftIcon className={classes.leftIcon} />}
            {label}
            {RightIcon && <RightIcon className={classes.rightIcon} />}
        </StyledButton>
    );
};

export default TextButton;
