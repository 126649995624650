import { fetchData, fetchFile } from "api/fetch";
import { EMAIL_TYPE, SNACKBAR_VARIANT_TYPE } from "enums";
import {
    createEmail,
    createMedia,
    deleteEmail,
    deleteMedia,
    getEmail
} from "store/medias/endpoints";
import { surveysStore, uiControlStore } from "store/store";
import { putSurveyEmail } from "store/surveys/endpoints";
import { getId } from "utils";

const actions = (set, get) => ({
    fetchEmail: id => fetchData(getEmail(id)),

    createMediaThunk: async media => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        const formData = new FormData();
        formData.append("file", media);

        return fetchFile(createMedia(), formData)
            .then(res => res)
            .catch(() => setSnackbar(true, "Cannot create media", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    deleteMediaThunk: async media_id => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(deleteMedia(media_id))
            .then(res => res)
            .catch(() => setSnackbar(true, "Cannot delete media", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    removeAndCreateMedia: (mediaToRemove, mediaToCreate) => {
        get().deleteMediaThunk(mediaToRemove);
        return get().createMediaThunk(mediaToCreate);
    },

    createEmailThunk: async (media, code, type) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        const formData = new FormData();
        formData.append("file", media);
        formData.append(type, code);

        return fetchFile(createEmail(), formData)
            .then(res => {
                return res;
            })
            .catch(() => setSnackbar(true, "Cannot create email", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    addMailToSurvey: async (mail, type) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const setEditingSurvey = surveysStore.getState().setEditingSurvey;
        const editingSurvey = surveysStore.getState().editingSurvey;
        const editingSurveyId = editingSurvey.id;
        const launchEmail = editingSurvey.launchEmail.map(mail => mail["@id"]);
        const triggerEmail = editingSurvey.triggerEmail.map(mail => mail["@id"]);

        const new_mail = {};

        switch (type) {
            case EMAIL_TYPE.LAUNCH:
                new_mail.launchEmail = [...launchEmail, mail["@id"]];
                break;
            case EMAIL_TYPE.RECONTACT:
                new_mail.triggerEmail = [...triggerEmail, mail["@id"]];
                break;
            default:
                new_mail.launchEmail = [];
                new_mail.triggerEmail = [];
                break;
        }

        return fetchData(putSurveyEmail(editingSurveyId), new_mail)
            .then(res => {
                setEditingSurvey({
                    ...editingSurvey,
                    launchEmail: res.launchEmail,
                    triggerEmail: res.triggerEmail
                });
            })
            .catch(err =>
                setSnackbar(true, "Cannot add email to survey", SNACKBAR_VARIANT_TYPE.ERROR)
            );
    },

    removeMailToSurvey: async (mail, type) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const setEditingSurvey = surveysStore.getState().setEditingSurvey;
        const editingSurvey = surveysStore.getState().editingSurvey;
        const editingSurveyId = editingSurvey.id;
        const launchEmail = editingSurvey.launchEmail.map(mail => mail["@id"]);
        const triggerEmail = editingSurvey.triggerEmail.map(mail => mail["@id"]);

        const updated_mail = {};
        switch (type) {
            case EMAIL_TYPE.LAUNCH:
                updated_mail.launchEmail = launchEmail.filter(x => x !== mail["@id"]);
                break;
            case EMAIL_TYPE.RECONTACT:
                updated_mail.triggerEmail = triggerEmail.filter(x => x === mail["@id"]);
                break;
            default:
                updated_mail.launchEmail = [];
                updated_mail.triggerEmail = [];
                break;
        }

        await fetchData(deleteEmail(getId(mail))).catch(err =>
            setSnackbar(true, "Cannot delete email ", SNACKBAR_VARIANT_TYPE.ERROR)
        );

        return fetchData(putSurveyEmail(editingSurveyId), updated_mail)
            .then(res => {
                setEditingSurvey({
                    ...editingSurvey,
                    launchEmail: res.launchEmail,
                    triggerEmail: res.triggerEmail
                });
            })
            .catch(err =>
                setSnackbar(true, "Cannot remove email from survey", SNACKBAR_VARIANT_TYPE.ERROR)
            );
    }
});

export default actions;
