import { find, map } from "lodash";
import { useSelector } from "react-redux";
import { translationsStore, uiControlStore } from "store/store";
import * as qs from "qs";

const useTranslation = () => {
    const translations = translationsStore(state => state.translation);
    const selectedLanguage = uiControlStore(state => state.selectedLanguage);
    const interpolation = useSelector(state => state.interpolation_keys);
    const debugTrad = qs.parse(window.location.search, { ignoreQueryPrefix: true }).debugTrad;

    const t = content => {
        if (process.env.NODE_ENV === "development" && debugTrad) return content;

        const trad = find(translations, val => val.uniqId === content);

        content = tFallback(trad, content);

        map(interpolation, (value, key) => {
            let regEx = new RegExp("{{" + key + "}}", "g");
            content = content.replace(regEx, value);
        });
        return content || "No english translation";
    };

    const tFallback = (trad, key) => {
        if (process.env.NODE_ENV === "development" && debugTrad) return key;
        try {
            return fallback(trad.content);
        } catch (err) {
            try {
                return fallback(trad);
            } catch (e) {
                return `#${key}#`;
            }
        }
    };

    const fallback = trad => {
        try {
            if (trad[selectedLanguage].valid) {
                return trad[selectedLanguage].value;
            } else {
                return trad["en"].value;
            }
        } catch (e) {
            if (trad["en"].valid) {
                return trad["en"].value;
            } else {
                return "no translation";
            }
        }
    };

    return { t, tFallback };
};

export default useTranslation;
