import { getId } from "utils";
import * as TYPES from "./types";
import { translationsStore, uiControlStore } from "store/store";
import { fetchData } from "api/fetch";
import { fitCampaignJsonApi } from "./formatters";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { CAMPAIGN_PAGE_MODE } from "enums";
import { postCampaign, putCampaign } from "./endpoints";

/**
 * Set the flagDuplicate.
 * This flag is use to trigger a duplication of a campaign.
 *
 * @returns
 */
export const setFlagDuplicate = flagState => ({
    type: TYPES.SET_FLAG_DUPLICATE,
    payload: flagState
});

/**
 * Set the flagModification.
 *
 * @returns
 */
export const setFlagModification = flagState => ({
    type: TYPES.SET_FLAG_MODIFICATION,
    payload: flagState
});

/**
 * Reset campaign to the inital state value.
 *
 * @returns
 */
export const resetCampaign = () => ({
    type: TYPES.RESET_CAMPAIGN
});

/**
 * Reset campaign editing to the inital state value.
 *
 * @returns
 */
export const resetCampaignEditing = () => ({
    type: TYPES.RESET_CAMPAIGN_EDITING
});

/**
 * Set Campaign page mode. Used to switch campaign component (Listing, creation, edition...).
 *
 * @param {Number} pageMode constants/Campaigns/{CAMPAIGN_PAGE_MODE}
 * @returns void
 */
export const setCampaignPageMode = pageMode => ({
    type: TYPES.SET_CAMPAIGN_PAGE_MODE,
    payload: pageMode
});

/**
 *  Set Campaign object.
 *  You can to provid a partial object containing some campaign props.
 *  This action will udpate the props provided only.
 *
 * @param {Object} campaign
 * @returns void
 */
export const setCampaign = campaign => ({ type: TYPES.SET_CAMPAIGN, payload: campaign });

/**
 *  Set Campaign object used for edition.
 *  You can to provid a partial object containing some campaign props.
 *  This action will udpate the props provided only.
 *
 * @param {Object} campaign
 * @returns void
 */
export const setCampaignEditing = campaign => ({
    type: TYPES.SET_CAMPAIGN_EDITING,
    payload: campaign
});

/**
 * Set the entire collection of email_files for the campaign.
 *
 * @param {Array} emailFiles string[], ex: ["/api/email_files/259","/api/email_files/259"]
 * @returns void
 */
export const setCampaignEmailFiles = emailFiles => ({
    type: TYPES.SET_CAMPAIGN_EMAIL_FILES,
    payload: emailFiles
});

/**
 * Add one email_file to the email_files collection of the campaign.
 *
 * @param {Object} emailFile
 * @returns void
 */
export const addCampaignEmailFile = emailFile => (dispatch, getState) => {
    const currFiles = getState().campaigns.campaign.files;
    dispatch(setCampaignEmailFiles([...currFiles, emailFile]));
};

/**
 * Remove the email_file from the email_files collection of the campaign.
 *
 * @param {Number} emailFileId
 * @returns
 */
export const removeCampaignEmailFile = emailFileId => (dispatch, getState) => {
    const currFiles = getState().campaigns.campaign.files;
    dispatch(setCampaignEmailFiles([...currFiles.filter(f => getId(f) !== emailFileId)]));
};

/**
 * Create a campaign.
 *
 * @returns
 */
export const createCampaign = () => async (dispatch, getState) => {
    const campaign = getState().campaigns.campaign;
    const createOneTranslation = translationsStore.getState().createOneTranslation;
    const setSnackbar = uiControlStore.getState().setSnackbar;

    try {
        const title = await createOneTranslation(campaign.title, "campaign.title");

        fetchData(postCampaign(), fitCampaignJsonApi(campaign, title))
            .then(() => {
                setSnackbar(true, "Campaign created", SNACKBAR_VARIANT_TYPE.SUCCESS);
                dispatch(resetCampaign());
                dispatch(setCampaignPageMode(CAMPAIGN_PAGE_MODE.LIST));
                dispatch(setFlagModification(false));
            })
            .catch(err => {
                console.error(err);
                setSnackbar(true, "Cannot create campaign", SNACKBAR_VARIANT_TYPE.ERROR);
                dispatch(setFlagModification(false));
            });
    } catch (ex) {
        console.error(ex);
        setSnackbar(true, "Cannot create campaign", SNACKBAR_VARIANT_TYPE.ERROR);
    }
};

/**
 * Duplicate a campaign.
 *
 * @param {Object} campaign
 * @returns
 */
export const duplicateCampaign = campaign => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;
    try {
        const titleValue = campaign.title.content.en.value + "-duplicated";
        const createOneTranslation = translationsStore.getState().createOneTranslation;

        const title = createOneTranslation(titleValue, "campaign.title");
        fetchData(postCampaign(), fitCampaignJsonApi({ ...campaign, state: 0 }, title))
            .then(() => {
                setSnackbar(true, "Campaign duplicated", SNACKBAR_VARIANT_TYPE.SUCCESS);
                dispatch(resetCampaign());
                dispatch(setCampaignPageMode(CAMPAIGN_PAGE_MODE.LIST));
                dispatch(setFlagDuplicate(false));
            })
            .catch(err => {
                console.error(err);
                setSnackbar(true, "Cannot duplicate campaign", SNACKBAR_VARIANT_TYPE.ERROR);
            });
    } catch (ex) {
        console.error(ex);
        setSnackbar(true, "Cannot duplicate campaign", SNACKBAR_VARIANT_TYPE.ERROR);
    }
};

/**
 * Update a campaign.
 * The title translation will be applied in all languages.
 *
 * @returns
 */
export const modifyCampaign = () => (dispatch, getState) => {
    const campaignNew = getState().campaigns.campaign;
    const campaignOld = getState().campaigns.campaignEditing;
    const editTranslation = translationsStore.getState().editTranslation;
    const setSnackbar = uiControlStore.getState().setSnackbar;

    try {
        Object.entries(campaignOld.title.content).forEach(lang => {
            if (lang[0]) {
                editTranslation(
                    getId(campaignOld.title),
                    campaignNew.title,
                    lang[0],
                    lang[1].valid
                );
            }
        });
        fetchData(putCampaign(campaignNew.id), fitCampaignJsonApi(campaignNew, campaignOld.title))
            .then(() => {
                setSnackbar(true, "Campaign updated", SNACKBAR_VARIANT_TYPE.SUCCESS);
                dispatch(resetCampaign());
                dispatch(resetCampaignEditing());
                dispatch(setCampaignPageMode(CAMPAIGN_PAGE_MODE.LIST));
                dispatch(setFlagModification(false));
            })
            .catch(err => {
                console.error(err);
                setSnackbar(true, "Cannot update campaign", SNACKBAR_VARIANT_TYPE.ERROR);
                dispatch(setFlagModification(false));
            });
    } catch (ex) {
        console.error(ex);
        setSnackbar(true, "Cannot update campaign", SNACKBAR_VARIANT_TYPE.ERROR);
    }
};
