import { find } from "lodash";
import * as RESULT_TABS from "enums/ResultTabs";
import { MIN_ANSWERS_RULE } from "enums";

export const selectedFilterSelector = state => state.results.selected_filter;
export const selectedBoxFilterSelector = state => state.results.selected_box_filter;
export const countryNotAuthorizedSelector = state => state.results.countryNotAuthorized;

export const myBlooming = state => {
    if (state.compilations.ifCompilation) {
        return state.compilations.resultPleasure;
    } else {
        return state.results.me[0];
    }
};

export const myThematic = state => {
    if (state.compilations.ifCompilation) {
        return state.compilations.resultThematic;
    } else {
        return state.results.me[1];
    }
};

export const myQuestions = state => {
    if (state.compilations.ifCompilation) {
        return state.compilations.resultQuestion[0];
    } else {
        return state.results.me[2][0];
    }
};

export const myFreeQuestion = state => {
    if (state.compilations.ifCompilation) {
        return state.compilations.resultFreeQuestion[0];
    } else {
        return state.results.me[3][0].freeAnswer;
    }
};

export const teamBlooming = state => {
    if (!state.results.survey.isAReplay) {
        if (state.results.selected_filter === 2 && state.results.team_one.length > 4) {
            return state.results.team_one[4];
        } else if (state.results.selected_filter === 5 && state.results.team_one.length > 4) {
            return state.results.team_one[4];
        } else if (state.results.selected_filter === 3 && state.results.team_n.length > 4) {
            return state.results.team_n[4];
        } else if (state.results.selected_filter === 1 && state.results.team.length > 4) {
            return state.results.team[4];
        } else if (
            state.results.selected_filter === 0 &&
            state.results.team.length > 4 &&
            state.results.team[0].nbrAnswer >= MIN_ANSWERS_RULE
        ) {
            return state.results.team[4];
        }
        return [];
    }
    return [];
};

export const teamThematic = state => {
    if (state.results.selected_filter === 2 && state.results.team_one.length > 1) {
        return state.results.team_one[1][0][0];
    } else if (state.results.selected_filter === 5 && state.results.team_one.length > 4) {
        return state.results.team_one[1][1][0];
    } else if (state.results.selected_filter === 3 && state.results.team_n.length > 1) {
        return state.results.team_n[1][0][0];
    } else if (state.results.selected_filter === 1 && state.results.team.length > 1) {
        return state.results.team[1][0][0];
    } else if (
        state.results.selected_filter === 0 &&
        state.results.team.length > 1 &&
        state.results.team[0][0].nbrAnswer >= MIN_ANSWERS_RULE
    ) {
        return state.results.team[1][0][0];
    }
    return [];
};

export const teamQuestions = state => {
    if (state.results.selected_filter === 2 && state.results.team_one.length > 2) {
        return state.results.team_one[2][0][0];
    } else if (state.results.selected_filter === 5 && state.results.team_one.length > 4) {
        return state.results.team_one[2][0][1];
    } else if (state.results.selected_filter === 3 && state.results.team_n.length > 2) {
        return state.results.team_n[2][0][0];
    } else if (state.results.selected_filter === 1 && state.results.team.length > 2) {
        return state.results.team[2][0][0];
    } else if (
        state.results.selected_filter === 0 &&
        state.results.team.length > 2 &&
        state.results.team[0].nbrAnswer >= MIN_ANSWERS_RULE
    ) {
        return state.results.team[2][0][0];
    }
    return [];
};

export const teamFreeQuestion = state => {
    if (state.results.selected_filter === 2 && state.results.team_one.length > 3) {
        return state.results.team_one[3][0].freeAnswer;
    } else if (state.results.selected_filter === 3 && state.results.team_n.length > 3) {
        return state.results.team_n[3][0].freeAnswer;
    } else if (state.results.selected_filter === 1 && state.results.team.length > 3) {
        return state.results.team[3][0].freeAnswer;
    }
    return [];
};

export const surveyIdSelector = state => state.results.survey.id;

export const surveyIsAReplayelector = state => state.results.survey.isAReplay;

export const surveyMediaSelector = state => state.results.survey.media;

export const surveyMediaContentSelector = state => state.results.mediaContent;

export const surveyResultSelector = state => state.results.survey;

export const shouldHaveLeadershipFilterSelector = state => {
    if (state.results.team_one) {
        if (state.results.team_one.length < 2) {
            return false;
        }
        if (state.results.team_one[2]) {
            return state.results.team_one[2].length > 1;
        }
    } else {
        return false;
    }
};

export const countryBlooming = state =>
    state.results.survey.isAReplay ? [] : state.results.country[0];

export const countryThematic = state =>
    state.results.survey.isAReplay
        ? []
        : state.results.country[1]
        ? state.results.country[1][0]
        : [];

export const countryQuestions = state =>
    state.results.survey.isAReplay
        ? []
        : state.results.country[2]
        ? state.results.country[2][0]
        : [];

/**
 *  Retrieve specific country question by id.
 *
 *  Usefull for getting the country question attached to a team question for comparaison.
 *
 * @param {*} state
 * @param {*} id Question unique identifier: question.gq
 * @returns question object or null
 */
export const countryQuestionsById = (state, id) => {
    if (state.results.survey.isAReplay) return null;
    if (state.results.country[2]) {
        return find(state.results.country[2][0], q => q.gq == id);
    }
    return null;
};

export const countryBloomingUnited = state =>
    state.results.survey.isAReplay ? [] : state.results.countryUnitedBlooming;

export const countryThematicUnited = state => {
    if (state.results.survey.isAReplay) {
        return [];
    } else if (state.results.countryUnitedThematic) {
        return state.results.countryUnitedThematic[0];
    } else {
        return [];
    }
};

export const countryQuestionsUnited = state =>
    state.results.survey.isAReplay
        ? []
        : state.results.countryUnitedQuestion
        ? state.results.countryUnitedQuestion[0]
        : [];

export const surveyTitleSelector = state => state.results.survey.title;

export const participationRateSelector = state => {
    if (state.results.selected_filter === 2 && state.results.team_one.length) {
        return state.results.team_one[0][0];
    } else if (
        (state.results.selected_filter === 1 || state.results.selected_filter === 0) &&
        state.results.team.length
    ) {
        return state.results.team[0][0];
    } else if (state.results.selected_filter === 3 && state.results.team_n.length >= 1) {
        return state.results.team_n[0][0];
    } else if (state.results.selected_filter === 4 && state.results.country[3]) {
        return state.results.country[3][0];
    }
    return [];
};

export const teamParticipationRateSelector = state => {
    if (state.results.selected_filter === 2 && state.results.team_one.length >= 1) {
        return state.results.team_one[0][0];
    } else if (
        (state.results.selected_filter === 1 ||
            state.results.selected_filter === 0 ||
            state.results.selected_filter === 4) &&
        state.results.team.length >= 1
    ) {
        return state.results.team[0][0];
    } else if (state.results.selected_filter === 3 && state.results.team_n.length >= 1) {
        return state.results.team_n[0][0];
    }
    return [];
};

export const loaderDataResultSelector = state => state.results.loader;

/**
 *  Check if the country result request is pending
 *
 * @param {*} state
 * @returns boolean
 */
export const countryResultsIsLoading = state => state.results.fetchingCountryResults;

/**
 *  Return a value representing the number of teams results requests pending.
 *
 * @param {*} state
 * @returns number
 */
export const teamsResultsAreLoading = state => state.results.fetchingTeamsResults;

export const surveyStartDateSelector = state => state.results.survey.expectedStartDate;

export const surveyEndDateSelector = state => state.results.survey.expectedEndDate;

export const verifyLoaderSelector = state => state.results.verifyLoader;

export const teamLengthSelector = state => state.results.team.length;

export const team1LengthSelector = state => state.results.team_one.length;

export const teamNLengthSelector = state => state.results.team_n.length;

export const getVerifyError403Team = state => state.results.verifyError403Team;

export const getVerifyError403TeamOne = state => state.results.verifyError403TeamOne;

export const getVerifyError403TeamN = state => state.results.verifyError403TeamN;

export const surveyReplaySelector = state => state.results.survey.isAReplay;

export const surveyTitleTranslationSelector = state => state.results.surveyTitleTranslation;

export const surveyTranslationIdSelector = state => state.results.survey.title;

// ===========================================================================
//                      Reworked Redux for Results
// ===========================================================================

//
//  Survey
//
export const getResultSurveySelector = state => {
    return state.results.survey;
};

export const getRefSurveyTitleSelector = state => {
    const thematics = state.results.my.thematics;
    return thematics.value?.GLOBAL?.content;
};

//
//  My
//
export const getMyPleasureSelector = state => {
    return state.results.my.pleasure;
};

export const getMyThematicsSelector = state => {
    return state.results.my.thematics;
};

export const getMyQuestionsSelector = state => {
    return state.results.my.questions;
};

export const getMyCommentsSelector = state => {
    return state.results.my.comments;
};
//
//  Team0
//
export const getTeamZeroPleasureSelector = state => {
    return state.results.teamZero.pleasure;
};

export const getTeamZeroCommentsSelector = state => {
    return state.results.teamZero.comments;
};

export const getTeamZeroThematicsSelector = state => {
    return state.results.teamZero.thematics;
};

export const getTeamZeroQuestionsSelector = state => {
    return state.results.teamZero.questions;
};

export const getTeamZeroAuthorizeSelector = state => {
    return state.results.teamZero.access;
};

export const getTeamZeroRateSelector = state => {
    return state.results.teamZero.participationRate;
};
//
//  Team1
//
export const getTeamOnePleasureSelector = state => {
    return state.results.teamOne.pleasure;
};

export const getTeamOneCommentsSelector = state => {
    return state.results.teamOne.comments;
};

export const getTeamOneThematicsSelector = state => {
    return state.results.teamOne.thematics;
};

export const getTeamOneQuestionsSelector = state => {
    return state.results.teamOne.questions;
};

export const getTeamOneAuthorizeSelector = state => {
    return state.results.teamOne.access;
};

export const getTeamOneRateSelector = state => {
    return state.results.teamOne.participationRate;
};
//
//  TeamN
//
export const getTeamNPleasureSelector = state => {
    return state.results.teamN.pleasure;
};

export const getTeamNThematicsSelector = state => {
    return state.results.teamN.thematics;
};

export const getTeamNCommentsSelector = state => {
    return state.results.teamN.comments;
};

export const getTeamNQuestionsSelector = state => {
    return state.results.teamN.questions;
};

export const getTeamNAuthorizeSelector = state => {
    return state.results.teamN.access;
};

export const getTeamNRateSelector = state => {
    return state.results.teamN.participationRate;
};
//
//  Generic team selectors : Team by selected tab
//
export const getTeamPleasureSelector = state => {
    switch (state.results.selected_filter) {
        case RESULT_TABS.MY_TEAM_RESULTS.value:
            return getTeamZeroPleasureSelector(state);
        case RESULT_TABS.MY_DIRECT_TEAM_RESULTS.value:
            return getTeamOnePleasureSelector(state);
        case RESULT_TABS.MY_TEAMS_PERIMETERS_RESULTS.value:
            return getTeamNPleasureSelector(state);
        default:
            throw new Error("Selected filter is not compliant!");
    }
};
export const getTeamThematicsSelector = state => {
    switch (state.results.selected_filter) {
        case RESULT_TABS.MY_TEAM_RESULTS.value:
            return getTeamZeroThematicsSelector(state);
        case RESULT_TABS.MY_DIRECT_TEAM_RESULTS.value:
            return getTeamOneThematicsSelector(state);
        case RESULT_TABS.MY_TEAMS_PERIMETERS_RESULTS.value:
            return getTeamNThematicsSelector(state);
        default:
            throw new Error("Selected filter is not compliant!");
    }
};
export const getTeamCommentsSelector = state => {
    switch (state.results.selected_filter) {
        case RESULT_TABS.MY_TEAM_RESULTS.value:
            return getTeamZeroCommentsSelector(state);
        case RESULT_TABS.MY_DIRECT_TEAM_RESULTS.value:
            return getTeamOneCommentsSelector(state);
        case RESULT_TABS.MY_TEAMS_PERIMETERS_RESULTS.value:
            return getTeamNCommentsSelector(state);
        default:
            throw new Error("Selected filter is not compliant!");
    }
};
export const getTeamQuestionsSelector = state => {
    switch (state.results.selected_filter) {
        case RESULT_TABS.MY_TEAM_RESULTS.value:
            return getTeamZeroQuestionsSelector(state);
        case RESULT_TABS.MY_DIRECT_TEAM_RESULTS.value:
            return getTeamOneQuestionsSelector(state);
        case RESULT_TABS.MY_TEAMS_PERIMETERS_RESULTS.value:
            return getTeamNQuestionsSelector(state);
        default:
            throw new Error("Selected filter is not compliant!");
    }
};
export const getTeamAuthorizeSelector = state => {
    switch (state.results.selected_filter) {
        case RESULT_TABS.MY_TEAM_RESULTS.value:
            return getTeamZeroAuthorizeSelector(state);
        case RESULT_TABS.MY_DIRECT_TEAM_RESULTS.value:
            return getTeamOneAuthorizeSelector(state);
        case RESULT_TABS.MY_TEAMS_PERIMETERS_RESULTS.value:
            return getTeamNAuthorizeSelector(state);
        default:
            throw new Error("Selected filter is not compliant!");
    }
};
export const getTeamRateSelector = state => {
    switch (state.results.selected_filter) {
        case RESULT_TABS.MY_TEAM_RESULTS.value:
            return getTeamZeroRateSelector(state);
        case RESULT_TABS.MY_DIRECT_TEAM_RESULTS.value:
            return getTeamOneRateSelector(state);
        case RESULT_TABS.MY_TEAMS_PERIMETERS_RESULTS.value:
            return getTeamNRateSelector(state);
        default:
            throw new Error("Selected filter is not compliant!");
    }
};
//
//  Country
//
export const getCountryPleasureSelector = state => {
    return state.results.country.pleasure;
};

export const getCountryThematicsSelector = state => {
    return state.results.country.thematics;
};

export const getCountryQuestionsSelector = state => {
    return state.results.country.questions;
};

export const getCountryQuestionsByIdSelector = (id, state) => {
    return state.results.country.questions.value?.find(q => q.gq == id);
};

export const getCountryRateSelector = state => {
    return state.results.country.participationRate;
};

export const getCountryAccessSelector = state => {
    return state.results.country.access;
};
//
//  United
//
export const getUnitedPleasureSelector = state => {
    return state.results.united.pleasure;
};

export const getUnitedThematicsSelector = state => {
    return state.results.united.thematics;
};

export const getUnitedQuestionsSelector = state => {
    return state.results.united.questions;
};

export const getUnitedQuestionsByIdSelector = (id, state) => {
    return state.results.united.questions.value?.find(q => q.gq == id);
};
