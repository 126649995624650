import { Typography } from "@mui/material";

const PrimaryTitle = props => {
    const { text } = props;

    return (
        <Typography
            sx={theme => ({
                color: theme.palette.common.grey,
                fontSize: "1.4rem",
                fontStyle: "italic",
                fontWeight: "bold",
                textTransform: "uppercase"
            })}>
            {text}
        </Typography>
    );
};

export default PrimaryTitle;
