import { create } from "zustand";
import { persist } from "zustand/middleware";
import actions from "./actions";
import states from "./states";

const languagesStore = create(
    persist(
        (...a) => ({
            ...states,
            ...actions(...a)
        }),
        {
            name: "dtb-languages"
        }
    )
);

export default languagesStore;
