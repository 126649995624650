import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
const PREFIX = "ContainedButton";

const classes = {
    leftIcon: `${PREFIX}LeftIcon`,
    rightIcon: `${PREFIX}RightIcon`
};

const StyledButton = styled(Button)(({ theme, styled }) => ({
    backgroundColor: theme.palette.common[styled.color],
    color: theme.palette.common[styled.textColor],
    fontWeight: "bold",
    boxShadow: "none",
    [`& .${classes.leftIcon}`]: {
        fontSize: 20,
        marginRight: theme.spacing(1)
    },
    [`& .${classes.rightIcon}`]: {
        fontSize: 20,
        marginLeft: theme.spacing(1)
    }
}));

const DEFAULT_COLOR_BUTTON = "green";

const ContainedButton = props => {
    const {
        color = DEFAULT_COLOR_BUTTON,
        textColor = "white",
        LeftIcon,
        RightIcon,
        label,
        className,
        onClick = null,
        type,
        disabled
    } = props;

    return (
        <StyledButton
            disabled={disabled}
            type={type}
            className={`${className}`}
            onClick={onClick}
            variant="contained"
            styled={{ color: color, textColor: textColor }}>
            {LeftIcon && <LeftIcon className={classes.leftIcon} />}
            {label}
            {RightIcon && <RightIcon className={classes.rightIcon} />}
        </StyledButton>
    );
};

export default ContainedButton;
