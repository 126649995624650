import { isEmpty } from "lodash";
import moment from "moment";
import { tFallback } from "utils/translation";

export const formatSurvey = (res, selected_language, doneSurveys) => {
    if (!isEmpty(res)) {
        const {
            title,
            expectedStartDate,
            expectedEndDate,
            estimatedCompletionTime,
            description,
            media,
            target,
            refSurvey = 3,
            contracts
        } = res;

        return {
            surveyTitle: tFallback(selected_language)(title),
            surveyDateStart: moment.utc(expectedStartDate).toDate(),
            surveyDateEnd: moment.utc(expectedEndDate).toDate(),
            surveyEstimatedCompletionTime: estimatedCompletionTime,
            surveyDescription: tFallback(selected_language)(description),
            surveyPhoto: media === undefined ? null : media,
            surveyTargets: formatTarget(target),
            previousSurvey: formatPreviousSurvey(refSurvey, selected_language, doneSurveys),
            contractsToExclude: contracts
        };
    }

    return {
        surveyDateStart: moment()
            .utc()
            .toDate(),
        surveyDateEnd: moment()
            .utc()
            .add(1, "d")
            .toDate()
    };
};

const group = {
    COUNTRIES: "countries",
    SERVICES: "services",
    PEOPLE: "people",
    UNITED: "united"
};

export const formatSurveyToJson = (values, title, description, photo = null, target, contracts) => {
    return {
        title: title["@id"],
        description: description["@id"],
        expectedStartDate: moment(values.surveyDateStart).format("YYYY-MM-DD"),
        expectedEndDate: moment(values.surveyDateEnd).format("YYYY-MM-DD"),
        createdAt: moment()
            .utc()
            .toDate(),
        modifiedAt: moment()
            .utc()
            .toDate(),
        isValidated: false,
        isStarted: false,
        isActive: true,
        estimatedCompletionTime: parseInt(values.surveyEstimatedCompletionTime, 10),
        media: photo !== null ? photo["@id"] : null,
        forms: [],
        refSurvey: values.previousSurvey ? values.previousSurvey.value : null,
        target: formatToJson(target),
        isAReplay: false,
        contracts: contracts
    };
};

export const formatSurveyThunk = (newValues, photo = null, target, contracts) => {
    return {
        expectedStartDate: moment(newValues.surveyDateStart).format("YYYY-MM-DD"),
        expectedEndDate: moment(newValues.surveyDateEnd).format("YYYY-MM-DD"),
        modifiedAt: moment().toDate(),
        isValidated: false,
        isStarted: false,
        isActive: true,
        estimatedCompletionTime: parseInt(newValues.surveyEstimatedCompletionTime, 10),
        media: photo !== null ? photo["@id"] : null,
        refSurvey: newValues.previousSurvey ? newValues.previousSurvey.value : null,
        target: formatToJson(target),
        isAReplay: false,
        contracts: contracts
    };
};

const formatToJson = target => {
    const targets = {
        countries: [],
        services: [],
        people: [],
        united: false
    };

    target.forEach(t => {
        switch (t.group) {
            case group.COUNTRIES:
                targets.countries.push(t.value);
                break;
            case group.SERVICES:
                targets.services.push(t.value);
                break;
            case group.PEOPLE:
                targets.people.push(t.value);
                break;
            case group.UNITED:
                targets.united = true;
                break;
            default:
                break;
        }
    });

    return targets;
};

const formatTarget = target => {
    let targets = [];
    target.countries.forEach(p => {
        targets.push({
            value: p,
            label: p,
            group: group.COUNTRIES
        });
    });
    target.services.forEach(p => {
        targets.push({
            value: p,
            label: p,
            group: group.SERVICES
        });
    });
    target.people.forEach(p => {
        targets.push({
            value: p,
            label: p,
            group: group.PEOPLE
        });
    });
    if (target.united) {
        targets.push({
            value: group.UNITED.toUpperCase(),
            label: group.UNITED.toUpperCase(),
            group: group.UNITED
        });
    }

    return targets;
};

const formatPreviousSurvey = (id_survey, selected_language, doneSurveys) => {
    let result = null;
    doneSurveys.forEach(el => {
        if (el.id === id_survey) {
            result = {
                value: id_survey,
                label: tFallback(selected_language)(el.title)
            };
        }
    });

    return result;
};
