import { fetchData } from "api/fetch";
import { getMyProfile } from "./endpoints";
import { formatProfileData } from "./formatters";
import { translationsStore, uiControlStore, languagesStore } from "store/store";

const actions = (set, get) => ({
    setMyProfile: myProfile => set({ myProfile: myProfile }),

    fetchMyProfile: async () => {
        const fetchTranslations = translationsStore.getState().fetchTranslations;
        const fetchLanguages = languagesStore.getState().fetchLanguages;
        const setSelectedLanguage = uiControlStore.getState().setSelectedLanguage;
        // Need to fetch the languages before fetching profile, because we set profileLanguages based on what is stored in languages (state in languagesStore)
        await fetchLanguages();
        const languagesList = languagesStore.getState().languages;

        return fetchData(getMyProfile())
            .then(myProfile => {
                get().setMyProfile(
                    formatProfileData(
                        myProfile,
                        languagesList,
                        fetchTranslations,
                        setSelectedLanguage
                    )
                );
            })
            .catch(async () => {
                // TODO when migrating to axios, use retry instead
                return fetchData(getMyProfile())
                    .then(myProfile => {
                        get().setMyProfile(
                            formatProfileData(
                                myProfile,
                                languagesList,
                                fetchTranslations,
                                setSelectedLanguage
                            )
                        );
                    })
                    .catch(console.error);
            });
    }
});

export default actions;
