export const createMedia = () => ({
    method: "POST",
    suffix: "/media"
});

export const getOneMedia = id => ({
    method: "GET",
    suffix: `/media/${id}`
});

export const deleteMedia = id => ({
    method: "DELETE",
    suffix: `/media/${id}`
});

export const getEmail = id => ({
    method: "GET",
    suffix: `/email_files/${id}`
});

export const createEmail = () => ({
    method: "POST",
    suffix: "/email_files"
});

export const deleteEmail = id => ({
    method: "DELETE",
    suffix: `/email_files/${id}`
});
