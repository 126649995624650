import React from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import { Hidden } from "@mui/material";

const PREFIX = "LeftBar";

const classes = {
    drawer: `${PREFIX}Drawer`,
    drawerPaper: `${PREFIX}DrawerPaper`
};

const StyledHidden = styled(Hidden)(({ theme }) => ({
    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0
    },
    [`& .${classes.drawerPaper}`]: {
        backgroundColor: theme.palette.common["lightgrey"],
        width: drawerWidth,
        paddingTop: "40px"
    }
}));

const drawerWidth = 240;

const ANCHOR_POSITION = "left";

const LeftBar = props => {
    const { children, anchor = ANCHOR_POSITION } = props;

    return (
        <StyledHidden mdDown implementation="css">
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper
                }}
                anchor={anchor}>
                {children}
            </Drawer>
        </StyledHidden>
    );
};

export default LeftBar;
