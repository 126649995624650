import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/styles";
import { MobileDatePicker } from "@mui/x-date-pickers";

const PREFIX = "DatePickerDTB";

const classes = {
    root: `${PREFIX}Root`,
    input: `${PREFIX}Input`
};

const StyledLocalizationProvider = styled(LocalizationProvider)(({ theme }) => ({
    [`& .${classes.root}`]: {
        boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 1px 5px rgba(0,0,0,0.10)",
        borderRadius: theme.spacing(1),
        padding: "5px 10px"
    },

    [`& .${classes.input}`]: {
        color: theme.palette.common["blue"],
        textAlign: "center"
    }
}));

const DatePickerDTB = props => {
    const { onChange, value, minDate, disabled } = props;

    const onChangeDate = value => {
        onChange(value.toString());
    };

    return (
        <StyledLocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                disabled={disabled}
                disablePast
                value={dayjs(value)}
                onChange={onChangeDate}
                minDate={dayjs(minDate)}
                format="DD MMMM YYYY"
                slotProps={{
                    textField: {
                        size: "small",
                        inputProps: {
                            sx: {
                                color: theme => theme.palette.common.newBlue,
                                textAlign: "center"
                            }
                        }
                    }
                }}
                cancelLabel={<CloseIcon />}
                okLabel={<DoneIcon />}
            />
        </StyledLocalizationProvider>
    );
};

export default DatePickerDTB;
