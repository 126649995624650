import { tFallback } from "utils/translation";

export const formatQuestionForJsonApi = (
    values,
    name,
    help = null,
    tagsId = [],
    customLabel,
    priority
) => {
    const {
        create_question_category,
        create_question_question_type,
        create_question_isLocal
    } = values;
    const data = {
        content: name["@id"],
        help: help["@id"],
        tags: tagsId,
        category: create_question_category.value,
        qtype: create_question_question_type.value,
        customLabel: customLabel["@id"],
        isActive: true,
        isLocal: create_question_isLocal,
        priority: parseInt(priority)
    };
    if (help !== null) data["help"] = help["@id"];

    return data;
};
const DEFAULT_LANGUAGE = "en";

export const formatQuestion = values => {
    const { id, content, category, qtype, tags, customLabel, priority } = values;
    const data = {
        id: id,
        create_question_name: tFallback(DEFAULT_LANGUAGE)(content),
        create_question_category:
            category != null
                ? {
                      label: tFallback(DEFAULT_LANGUAGE)(category.label),
                      value: category["@id"]
                  }
                : null,
        create_question_question_type: {
            label: tFallback(DEFAULT_LANGUAGE)(qtype.name),
            value: qtype["@id"]
        },
        create_question_tags: tags.map(tag => ({
            label: tag.label.toUpperCase(),
            value: tag["@id"]
        })),
        create_question_custom_label:
            customLabel != null ? tFallback(DEFAULT_LANGUAGE)(customLabel) : null,
        create_question_priority: parseInt(priority)
    };

    if (values.help != null) {
        const dataCreateQuestionHelp = tFallback(DEFAULT_LANGUAGE)(values.help);
        data["create_question_help"] = dataCreateQuestionHelp.toUpperCase();
    }

    return data;
};
