import * as TYPES from "./types";
import { SURVEY_STATE } from "enums";

export const setSurveys = surveys => ({ type: TYPES.SET_SURVEYS, payload: surveys });

/**
 * Clear surveys caching
 * @returns
 */
export const reset = () => dispatch => {
    dispatch(setSurveys([]));
};

/**
 * Set started surveys used in surveys manager page.
 * @param {*} surveys
 * @returns
 */
export const setStartedSurveys = surveys => (dispatch, getState) => {
    const others = getState().surveysManager.surveys.filter(s => s.status !== SURVEY_STATE.STARTED);
    dispatch(setSurveys([...others, ...surveys]));
};

/**
 * Set editing surveys used in surveys manager page.
 * @param {*} surveys
 * @returns
 */
export const setEditingSurveys = surveys => (dispatch, getState) => {
    const others = getState().surveysManager.surveys.filter(
        s => s.status !== SURVEY_STATE.STAND_BY
    );
    dispatch(setSurveys([...others, ...surveys]));
};

/**
 * Set editing surveys used in surveys manager page.
 * @param {*} surveys
 * @returns
 */
export const setFinishedSurveys = surveys => (dispatch, getState) => {
    const others = getState().surveysManager.surveys.filter(s => s.status !== SURVEY_STATE.ENDED);
    dispatch(setSurveys([...others, ...surveys]));
};

/**
 * Replace a survey in the cache
 * @param {*} survey
 * @returns
 */
export const replaceSurvey = survey => (dispatch, getState) => {
    const others = getState().surveysManager.surveys.filter(s => s.id !== survey.id);
    dispatch(setSurveys([...others, survey]));
};

/**
 * Set a specific prop in the survey
 * @param {*} id
 * @param {*} propName
 * @param {*} payload
 * @returns
 */
export const updateSurveyProp = (id, propName, payload) => (dispatch, getState) => {
    const survey = getState().surveysManager.surveys.find(s => s.id === id);
    if (survey) {
        survey[propName] = payload;
        dispatch(replaceSurvey(survey));
    } else {
        console.error(`Unable to update prop ${propName} on survey ${id}`);
    }
};
