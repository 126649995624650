import { SET_ROLES } from "./types";

const stateInitial = [];

const roles = (state = stateInitial, action) => {
    switch (action.type) {
        case SET_ROLES: {
            return [...action.payload];
        }
        default: {
            return state;
        }
    }
};

export default roles;
