import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { createForm, getTargets, modifyForm, removeForm } from "store/formsSurvey/endpoints";
import { fitFormToJsonAPI } from "store/formsSurvey/formatters";
import { uiControlStore } from "store/store";

const actions = (set, get) => ({
    createUnitedForm: async question => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const questions = Object.keys(question).filter(value => question[value] === true);

        try {
            const res = await fetchData(createForm(), fitFormToJsonAPI(false, questions));
            return res;
        } catch {
            return setSnackbar(true, "Cannot create form", SNACKBAR_VARIANT_TYPE.ERROR);
        }
    },

    createLocalForm: async (question, country, leader) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const questions = Object.keys(question).filter(value => question[value] === true);
        try {
            const res = await fetchData(
                createForm(),
                fitFormToJsonAPI(true, questions, country, leader)
            );
            return res;
        } catch {
            return setSnackbar(true, "Cannot create form", SNACKBAR_VARIANT_TYPE.ERROR);
        }
    },

    editUnitedForm: async (form, form_id) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const questions = Object.keys(form).filter(value => form[value] === true);

        return fetchData(modifyForm(form_id), fitFormToJsonAPI(false, questions)).catch(() => {
            setSnackbar(true, "Cannot update form", SNACKBAR_VARIANT_TYPE.ERROR);
        });
    },

    editLocalForm: async (form, form_id, country, leader) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const questions = Object.keys(form).filter(value => form[value] === true);

        try {
            const res = await fetchData(
                modifyForm(form_id),
                fitFormToJsonAPI(true, questions, country, leader)
            );
            return res;
        } catch {
            return setSnackbar(true, "Cannot update form", SNACKBAR_VARIANT_TYPE.ERROR);
        }
    },

    deleteForm: async id => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(removeForm(id)).catch(() => {
            setSnackbar(true, "Cannot delete form", SNACKBAR_VARIANT_TYPE.ERROR);
        });
    },

    fetchTargets: async keyword => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(getTargets(keyword))
            .then(res => [
                {
                    label: "UNITED",
                    options: [{ value: "UNITED", label: "UNITED", group: "united" }]
                },
                ...res
            ])
            .catch(() => setSnackbar(true, "Cannot fetch targets", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    finalizeForm: form_id => fetchData(modifyForm(form_id), { isFinalized: true })
});
export default actions;
