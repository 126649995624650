export const getMyProfile = () => ({
    method: "GET",
    suffix: "/dtb_users/my_profile"
});

export const searchUser = user => ({
    method: "GET",
    suffix: `/dtb_users/search?search=${user}`
});

export const searchLeadersBySurveyByCountry = (surveyID, countryCode, searchQuery) => ({
    method: "GET",
    suffix: `/${surveyID}/search_users?country=${countryCode}&search=${searchQuery}`
});

export const getUser = userID => ({
    method: "GET",
    suffix: `/dtb_users/${userID}`
});

export const editUser = id => ({
    method: "PUT",
    suffix: `/dtb_users/${id}`
});

export const extractLdapUsers = date => ({
    method: "GET",
    suffix: `/dtb_users/download_users_extraction?date=${date}`
});
