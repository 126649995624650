export const getProfiles = () => ({
    method: "GET",
    suffix: "/profiles"
});

export const createProfiles = () => ({
    method: "POST",
    suffix: "/profiles"
});

export const modifyProfiles = id => ({
    method: "PUT",
    suffix: `/profiles/${id}`
});
