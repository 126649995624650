import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { uiControlStore } from "store/store";
import { createProfiles, getProfiles, modifyProfiles } from "./endpoints";

const actions = (set, _get) => ({
    fetchProfiles: async () => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        return fetchData(getProfiles())
            .then(json => {
                set({ profiles: [...json] });
            })
            .catch(_err => setSnackbar(true, "Cannot fetch profiles", SNACKBAR_VARIANT_TYPE.ERROR));
    },
    createProfile: values => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        fetchData(createProfiles(), values)
            .then(res => set(state => ({ profiles: [...state.profiles, res] })))
            .catch(_err => setSnackbar(true, "Cannot create profile", SNACKBAR_VARIANT_TYPE.ERROR));
    },
    editProfile: async values => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        const id = values.id;
        delete values.id;

        return fetchData(modifyProfiles(id), values)
            .then(res =>
                set(state => ({
                    profiles: [
                        ...state.profiles.map(profile =>
                            profile.id == res.id ? res : { ...profile }
                        )
                    ]
                }))
            )
            .catch(_err => setSnackbar(true, "Cannot create profile", SNACKBAR_VARIANT_TYPE.ERROR));
    }
});

export default actions;
