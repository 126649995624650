import { SNACKBAR_VARIANT_TYPE } from "enums";

const states = {
    messageSnackbar: "",
    openSnackbar: false,
    variantSnackbar: SNACKBAR_VARIANT_TYPE.INFO,
    selectedLanguage: "en",
    languageDirection: "ltr",
    adminIndex: 0,
    percentageAnswers: null
};

export default states;
