import React from "react";
import { styled } from "@mui/material/styles";
import { Label } from "components/common";
import useTranslation from "hooks/useTranslation";

const PREFIX = "ComponentWithLabel";

const classes = {
    container: `${PREFIX}Container`,
    required: `${PREFIX}Required`,
    label: `${PREFIX}Label`
};

const Root = styled("div")(() => ({
    [`& .${classes.container}`]: {
        padding: "5px 10px",
        display: "flex",
        alignSelf: "stretch"
    },
    [`& .${classes.required}`]: {
        fontSize: "0.9rem",
        alignSelf: "center",
        fontStyle: "italic"
    },
    [`& .${classes.label}`]: {
        marginRight: "1rem",
        alignSelf: "center"
    }
}));

const ComponentWithLabel = props => {
    const { label, children, center, className, required = false } = props;

    const { t } = useTranslation();

    const optional = t("admin.comparative.optionnal");
    return (
        <Root>
            <div className={`${classes.container} ${className}`}>
                <Label className={`${classes.label}`} text={label} center={center} />
                <span className={`${classes.required}`}>{required && `(${optional})`}</span>
            </div>
            {children}
        </Root>
    );
};

export default ComponentWithLabel;
