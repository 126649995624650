import { mediasStore } from "store/store";

export const checkPhoto = (initialValues, submitValues) => {
    const { createMediaThunk, deleteMediaThunk, removeAndCreateMedia } = mediasStore.getState();

    if (initialValues.media === null) {
        if (oneNewMedia(initialValues, submitValues)) {
            console.log("oneNewMedia");
            return () => createMediaThunk(submitValues.surveyPhoto);
        }
        if (noMedia(initialValues, submitValues)) {
            console.log("noMedia");
            return null;
        }
    } else {
        if (removeMedia(initialValues, submitValues)) {
            console.log("removeMedia");
            return () => deleteMediaThunk(initialValues.media.id);
        }
        if (sameMedia(initialValues, submitValues)) {
            console.log("sameMedia");
            return null;
        }
        if (editMedia(initialValues, submitValues)) {
            console.log("oneNeditMediaewMedia");
            return () => removeAndCreateMedia(initialValues.media.id, submitValues.surveyPhoto);
        }
    }
};

const oneNewMedia = (initialValuesPart, submitValues) =>
    initialValuesPart.media === null && submitValues.surveyPhoto !== null;
const removeMedia = (initialValuesPart, submitValues) =>
    initialValuesPart.media !== null && submitValues.surveyPhoto === null;
const noMedia = (initialValues, submitValues) =>
    initialValues.media === null && submitValues.surveyPhoto === null;
const sameMedia = (initialValues, submitValues) =>
    initialValues.media["@id"] === submitValues.surveyPhoto["@id"];
const editMedia = (initialValuesPart, submitValues) =>
    initialValuesPart.media !== null && submitValues.surveyPhoto !== null;
