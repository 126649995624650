export const HOME_URL = "/";
export const SIGN_IN_URL = "/sign_in";
export const SIGN_OUT_URL = "/sign_out";
export const ADMIN_URL = "/admin";

// ADMIN - SURVEYS MANAGER
export const ADMIN_SURVEY_URL = "/admin/surveys";
export const ADMIN_SURVEYS_STARTED = "/admin/surveys/started";
export const ADMIN_SURVEYS_EDITION = "/admin/surveys/edition";
export const ADMIN_SURVEYS_FINISHED = "/admin/surveys/finished";

// ADMIN - SEARCH
export const ADMIN_SEARCH = "/admin/search";
export const ADMIN_SEARCH_USER = "/admin/search/user";
export const ADMIN_SEARCH_USER_LDAP = "/admin/search/user-ldap";
export const ADMIN_SEARCH_USER_SURVEY = "/admin/search/user-survey";
export const ADMIN_SEARCH_USER_IMPORT = "/admin/search/imports-users";

// ADMIN - VERBATIM
export const ADMIN_VERBATIM = "/admin/verbatim";
export const ADMIN_VERBATIM_REVIEW = "/admin/verbatim/reviews";

// ADMIN - USERS RIGHTS MANAGEMENT
export const ADMIN_USERS_MANAGEMENT = "/admin/rights";
export const ADMIN_RIGHTS_MANAGEMENT_USER = "/admin/rights/user";
export const ADMIN_RIGHTS_MANAGEMENT_PROFILE = "/admin/rights/profile";
export const ADMIN_RIGHTS_MANAGEMENT_PROFILE_CREATE = "/admin/rights/profile/new";
export const ADMIN_RIGHTS_MANAGEMENT_PROFILE_UPDATE = "/admin/rights/profile/:id";

// ADMIN - PERSONALIZED REPORT MANAGEMENT
export const ADMIN_PERSONALIZED_REPORTS = "/admin/personalized-report";
export const ADMIN_PERSONALIZED_REPORTS_RESULTS = "/admin/personalized-report/results";

// ADMIN - COUNTRY LEADERS RESULTS
export const ADMIN_COUNTRY_LEADERS_RESULTS = "/admin/leaders-results";

// RESULTS
export const RESULTS_URL = "/results/:surveyID";
export const RESULTS_PERSONAL_URL = "/results/:surveyID/personal";
export const RESULTS_TEAM0_URL = "/results/:surveyID/team";
export const RESULTS_TEAM1_URL = "/results/:surveyID/managed-team";
export const RESULTS_TEAMN_URL = "/results/:surveyID/total-perimeter";
export const RESULTS_LEADERS_URL = "/results/:surveyID/leaders";
export const RESULTS_COUNTRY_URL = "/results/:surveyID/country";

export const ADMIN_FORMS_MANAGEMENT = "/admin/forms";
export const ADMIN_TRANSLATIONS = "/admin/translations";
export const ADMIN_EXCEL_COMPARE = "/admin/excel-comparatives";
export const ADMIN_CAMPAIGNS_MANAGER = "/admin/campaigns";

export const ERROR_URL = "/error";
export const ERROR_PROFIL_URL = "/ErrorProfil";
export const NOT_FOUND_URL = "/not_found";
export const CREATE_SURVEY_URL = "/create_survey";
export const EDIT_SURVEY_URL = "/edit_survey";
export const PREVIEW_SURVEY_URL = "/preview_survey";
export const ANSWER_SURVEY_URL = "/answer_survey";
export const PROFILE_URL = "/my_profile";
export const MY_SURVEYS_URL = "/my_surveys";

export const MY_ANSWERS_URL = "/my_answers";
export const NOT_ALLOWED_PAGE = "/not_allowed";
export const MY_TEAM_URL = "/my_team";
export const TEAM_GREATING_URL = "/my_team_greating";
