import { Box, Stack, Typography } from "@mui/material";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import logoUrl from "assets/logos/decathlon_university_white.png";

const MaintenancePage = () => {
    const message = "Under maintenance";
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={theme => ({ height: "100%", bgcolor: theme.palette.common.newBlue })}>
            <Box m="50px">
                <img src={logoUrl} alt="dtb logo" style={{ height: "120px" }} />
            </Box>
            <Stack
                display="inline-flex"
                alignItems="center"
                direction="row"
                sx={{
                    fontSize: "2rem",
                    color: "white",
                    border: "2px solid white",
                    borderRadius: "15px",
                    p: "15px"
                }}>
                <ConstructionRoundedIcon fontSize="inherit" />
                <Typography
                    sx={{ mx: "15px", fontWeight: "600", textTransform: "uppercase" }}
                    fontSize="inherit">
                    {message}
                </Typography>
            </Stack>
        </Box>
    );
};

export default MaintenancePage;
