import { getLanguages } from "./endpoints";
import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";

import { uiControlStore } from "store/store";

const actions = (set, get) => ({
    languagesSortByCode: () => {
        const languages = get().languages;
        const sortedLangs = languages.sort((x, y) => {
            if (x.code < y.code) return -1;
            if (x.code > y.code) return 1;
            return 0;
        });

        return sortedLangs;
    },

    fetchLanguages: async () => {
        return fetchData(getLanguages())
            .then(json => {
                set({ languages: json });
            })
            .catch(() => {
                const setSnackbar = uiControlStore.getState().setSnackbar;
                setSnackbar(true, "Cannot fetch languages", SNACKBAR_VARIANT_TYPE.ERROR);
            });
    }
});

export default actions;
