import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";

const PREFIX = "ProgressPercentage";

const classes = {
    container: `${PREFIX}Container`,
    progress: `${PREFIX}Progress`,
    indicator: `${PREFIX}Indicator`,
    root: `${PREFIX}Root`,
    bar: `${PREFIX}Bar`,
    linear_progress: `${PREFIX}LinearProgress`
};

const Root = styled("div")(({ theme, styled }) => ({
    display: "flex",
    alignItems: "flex-start",
    [`& .${classes.progress}`]: {
        flexGrow: 1,
        textAlign: "center"
    },

    [`& .${classes.indicator}`]: style => ({
        position: "relative",
        display: "inline-block",
        textAlign: "center",
        marginLeft: theme.spacing(-1)
    }),

    [`& .${classes.root}`]: {
        height: styled.bar_height,
        backgroundColor: styled.bar_color
    },

    [`& .${classes.bar}`]: {
        backgroundColor: theme.palette.common[styled.color]
    },

    [`& .${classes.linear_progress}`]: {
        border: "1px solid black"
    }
}));

const DEFAULT_BAR_COLOR = "#FFFFFF";
const DEFAULT_HEIGHT_SIZE = 15;

const formatCompleted = value => {
    return value >= 100 ? 100 : value <= 0 ? 0 : value;
};

const ProgressPercentage = props => {
    let { completed } = props;
    const {
        color,
        bar_color = DEFAULT_BAR_COLOR,
        no_indicator,
        bar_height = DEFAULT_HEIGHT_SIZE,
        className
    } = props;

    const indicator =
        completed >= 0 && completed <= 100 ? (
            <Typography className={classes.indicator}>{completed} %</Typography>
        ) : (
            ""
        );

    return (
        <Root
            className={className}
            styled={{
                color: color,
                bar_color: bar_color,
                bar_height: bar_height
            }}>
            <div className={classes.progress}>
                <LinearProgress
                    classes={{
                        root: classes.root,
                        bar: classes.bar
                    }}
                    className={classes.linear_progress}
                    variant="determinate"
                    value={formatCompleted(completed)}
                />
                {!no_indicator && indicator}
            </div>
        </Root>
    );
};

export default ProgressPercentage;
