export const createForm = () => ({
    method: "POST",
    suffix: "/forms"
});

export const modifyForm = id => ({
    method: "PUT",
    suffix: `/forms/${id}`
});

export const removeForm = id => ({
    method: "DELETE",
    suffix: `/forms/${id}`
});

export const getTargets = keyword => ({
    method: "GET",
    suffix: `/surveys/target?search=${keyword}`
});