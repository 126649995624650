import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoTooltip = props => {
    const { text } = props;
    return (
        <Tooltip title={text}>
            <IconButton sx={{ height: "8px", width: "8px", fontSize: "20px", m: "5px" }}>
                <InfoOutlinedIcon fontSize="inherit" color="primary" />
            </IconButton>
        </Tooltip>
    );
};

export default InfoTooltip;
