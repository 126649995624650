import jwt_decode from "jwt-decode";
import moment from "moment";
import userProfileStore from "store/user";

const LOCALSTORAGE_TOKEN_KEY = process.env.REACT_APP_TOKEN_NAME;
const JOB_NOT_ALLOWED = "PREST.INFO";

const useAuthentication = () => {
    const clearProfile = userProfileStore(state => state.setMyProfile);

    const token = {
        get: () => localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
        set: token => localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token),
        delete: () => localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY),
        isValid: () => tokenIsValid(token.get())
    };

    /**
     * Check if the token is not expires if existing.
     * @param {*} token
     * @returns
     */
    const tokenIsValid = token =>
        token && moment(jwt_decode(token).exp).isSameOrAfter(moment().unix());

    /**
     * Check if the user is a presta.
     * @param {*} tokenId
     * @returns
     */
    const userIsPresta = tokenId => {
        return tokenId && jwt_decode(tokenId).jobname === JOB_NOT_ALLOWED;
    };

    /**
     *  Remove user profile object and localstorage token informations.
     */
    const removeAuthInfos = () => {
        clearProfile();
        localStorage.removeItem("auth");
        localStorage.removeItem("pkce");
        token.delete();
    };

    /**
     * Send revocation request to Decathlon FED
     */
    const signOut = () => {
        if (process.env.NODE_ENV === "development")
            return new Promise(resolve => {
                removeAuthInfos();
                resolve();
            });
        const details = {
            token: token.get(),
            client_id: process.env.REACT_APP_CLIENT_ID
        };

        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        return fetch(process.env.REACT_APP_FED_REVOKE_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: formBody
        });
    };

    const base64UrlEncode = value => {
        const base64 = btoa(JSON.stringify(value));
        return base64
            .replace("+", "-")
            .replace("/", "_")
            .replace(/=+$/, "");
    };

    const generateDevToken = uid => {
        const secretKey = process.env.REACT_APP_TOKEN_KEY;
        const header = base64UrlEncode({ alg: "HS256", typ: "JWT" });
        const payload = base64UrlEncode({
            uid: `${uid}`,
            iss: "dtb.local.dev",
            exp: moment()
                .add(2, "hours")
                .unix(),
            iat: moment().unix()
        });
        const signature = btoa(header + "." + payload + secretKey);
        const token = header + "." + payload + "." + signature;
        return token;
    };

    const devModeSignIn = uid => {
        return new Promise((resolve, reject) => {
            const genToken = generateDevToken(uid);
            token.set(genToken);
            resolve(genToken);
        });
    };

    return { signOut, removeAuthInfos, devModeSignIn, token, tokenIsValid, userIsPresta };
};

export default useAuthentication;
