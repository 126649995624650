import React from "react";
import { withSuspense } from "components/templates";

export const HomePage = withSuspense(React.lazy(() => import("./shared/HomePage")));
export const AnswerSurveyPage = withSuspense(React.lazy(() => import("./shared/AnswerSurveyPage")));
export const AnsweredSurveyPage = withSuspense(
    React.lazy(() => import("./shared/AnsweredSurveyPage"))
);
export const SignInPage = withSuspense(React.lazy(() => import("./shared/SignInPage")));
export const SignOutPage = withSuspense(React.lazy(() => import("./shared/SignOutPage")));
export const MyProfilePage = withSuspense(React.lazy(() => import("./shared/MyProfilePage")));
export const MySurveysPage = withSuspense(React.lazy(() => import("./shared/MySurveysPage")));
export const ResultsPage = withSuspense(React.lazy(() => import("./shared/ResultsPage")));
export const SurveyConceptorPage = withSuspense(
    React.lazy(() => import("./admin/SurveyConceptorPage"))
);
export const TeamManagerPage = withSuspense(React.lazy(() => import("./shared/TeamManagerPage")));
export const TeamGreatingPage = withSuspense(React.lazy(() => import("./shared/TeamGreatingPage")));
export const AdminPage = withSuspense(React.lazy(() => import("./admin/Admin")));
export const RightsManagerPage = withSuspense(
    React.lazy(() => import("./admin/RightsManagerPage"))
);
export const FormsManagerPage = withSuspense(React.lazy(() => import("./admin/FormsManagerPage")));
export const SurveysManagerPage = withSuspense(
    React.lazy(() => import("./admin/SurveysManagerPage"))
);
export const RelaysCountryLeadersResults = withSuspense(
    React.lazy(() => import("./admin/RelaysCountryLeadersResults"))
);
export const VerbatimPage = withSuspense(React.lazy(() => import("./admin/VerbatimPage")));
export const TranslationsManagerPage = withSuspense(
    React.lazy(() => import("./admin/TranslationsManagerPage"))
);
export const CompareManagerPage = withSuspense(
    React.lazy(() => import("./admin/CompareManagerPage"))
);
export const PersonalizedReportPage = withSuspense(
    React.lazy(() => import("./admin/PersonalizedReportPage"))
);
export const SearchPage = withSuspense(React.lazy(() => import("./admin/SearchPage")));
export const CampaignsManagerPage = withSuspense(
    React.lazy(() => import("./admin/CampaignsManagerPage"))
);
export const LoadingPage = withSuspense(React.lazy(() => import("./common/LoadingPage")));
export const ErrorPage = withSuspense(React.lazy(() => import("./common/ErrorPage")));
export const ErrorProfilePage = withSuspense(React.lazy(() => import("./common/ErrorProfilePage")));
export const NotAllowedPage = withSuspense(React.lazy(() => import("./common/NotAllowedPage")));
export const NotFoundPage = withSuspense(React.lazy(() => import("./common/NotFoundPage")));
export const MaintenancePage = withSuspense(React.lazy(() => require("./common/MaintenancePage")));
