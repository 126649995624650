export const getTranslations = params => ({
    method: "GET",
    suffix: `/translations${params ? `?${params}` : ""}`
});

export const getDynamicTranslations = (translationQuery, tradLanguage) => ({
    method: "GET",
    suffix: `/translations?static=false${translationQuery ? `&${translationQuery}` : ""}${
        tradLanguage ? `&language=${tradLanguage}` : ""
    }`
});

export const createTranslation = () => ({
    method: "POST",
    suffix: "/translations"
});

export const getOneTranslation = id => ({
    method: "GET",
    suffix: `/translations/${id}`
});

export const modifyTranslation = id => ({
    method: "PUT",
    suffix: `/translations/${id}`
});
