import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "Text";

const classes = {
    root: `${PREFIX}Root`
};

const StyledTypography = styled(Typography)(({ theme }) => ({
    [`& .${classes.root}`]: style => {
        const base_style = {
            color: theme.palette.common[style.color]
        };
        switch (style.variant) {
            case "normal":
                return {
                    ...base_style,
                    fontSize: "0.8rem"
                };
            case "stepper_step": {
                return {
                    ...base_style,
                    fontSize: "1rem",
                    fontWeight: "bold",
                    fontStyle: "italic"
                };
            }
            case "stepper_sub_step": {
                return {
                    ...base_style,
                    fontSize: "0.8rem",
                    fontStyle: "italic"
                };
            }
            default:
                return {
                    ...base_style
                };
        }
    }
}));

const Text = props => {
    const { text = "", variant = "normal", color = "black", className = "" } = props;
    return (
        <StyledTypography style={{ variant: variant, color: color }} className={className}>
            {text}
        </StyledTypography>
    );
};

export default Text;
