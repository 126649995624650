import { COUNTRIES_CODE_LABEL } from "enums/countries";

export const convertCountryCode = code => COUNTRIES_CODE_LABEL[code.toUpperCase()] || code;

export const compare = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};
