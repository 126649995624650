import { Grid } from "@mui/material";
import { Topbar } from "components/common";
import * as PAGES from "components/pages";
import ADMIN_MENU_ITEMS from "enums/AdminMenuItems";
import * as ROLES from "enums/Roles";
import { Route, Switch } from "react-router-dom";
import * as URLS from "routes/Urls";
import PrivateRoute from "./PrivateRoute";

const PrivateRoutesList = () => {
    const ADMIN_ROLES = ADMIN_MENU_ITEMS.flatMap(item => item.roles);
    return (
        <Grid container direction={"column"} height={"100%"}>
            <Topbar />
            <Grid item xs flex={1} marginTop={"64px"}>
                <Switch>
                    <PrivateRoute
                        path={URLS.ADMIN_URL}
                        Component={PAGES.AdminPage}
                        roles={ADMIN_ROLES}
                    />
                    <PrivateRoute
                        exact
                        path={URLS.CREATE_SURVEY_URL}
                        Component={PAGES.SurveyConceptorPage}
                        roles={[ROLES.SURVEY_CREATE]}
                    />
                    <PrivateRoute
                        exact
                        path={URLS.EDIT_SURVEY_URL}
                        Component={PAGES.SurveyConceptorPage}
                        roles={[ROLES.SURVEY_MODIFY]}
                    />
                    <PrivateRoute exact path={URLS.HOME_URL} Component={PAGES.HomePage} />
                    <PrivateRoute exact path={URLS.PROFILE_URL} Component={PAGES.MyProfilePage} />
                    <PrivateRoute
                        exact
                        path={URLS.MY_SURVEYS_URL}
                        Component={PAGES.MySurveysPage}
                    />
                    <PrivateRoute exact path={URLS.MY_TEAM_URL} Component={PAGES.TeamManagerPage} />
                    <PrivateRoute
                        exact
                        path={URLS.TEAM_GREATING_URL}
                        Component={PAGES.TeamGreatingPage}
                    />
                    <PrivateRoute path={URLS.RESULTS_URL} Component={PAGES.ResultsPage} />
                    <PrivateRoute
                        exact
                        path={URLS.ANSWER_SURVEY_URL + "/:id"}
                        Component={PAGES.AnswerSurveyPage}
                    />
                    <PrivateRoute
                        exact
                        path={URLS.PREVIEW_SURVEY_URL + "/:id"}
                        Component={PAGES.AnswerSurveyPage}
                    />
                    <PrivateRoute
                        exact
                        path={URLS.MY_ANSWERS_URL + "/:id"}
                        Component={PAGES.AnsweredSurveyPage}
                    />
                    <Route component={PAGES.NotFoundPage} />
                </Switch>
            </Grid>
        </Grid>
    );
};

export default PrivateRoutesList;
