import { SET_ROLES } from "./types";
import { fetchData } from "../../api/fetch";
import { getRoles } from "./endpoints";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { uiControlStore } from "store/store";

export const setRoles = roles => ({
    type: SET_ROLES,
    payload: roles
});

export const fetchRoles = () => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getRoles())
        .then(res => dispatch(setRoles(res)))
        .catch(err =>
            dispatch(setSnackbar((true, "Cannot fetch rights", SNACKBAR_VARIANT_TYPE.ERROR)))
        );
};
