import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoutesList from "./PrivateRoutesList";
import * as PAGES from "components/pages";
import * as URLS from "./Urls";

const Routes = () => {
    return (
        <Switch>
            <Route exact path={URLS.SIGN_IN_URL} component={PAGES.SignInPage} />
            <Route exact path={URLS.SIGN_OUT_URL} component={PAGES.SignOutPage} />
            <Route exact path={URLS.ERROR_URL} component={PAGES.ErrorPage} />
            <Route exact path={URLS.ERROR_PROFIL_URL} component={PAGES.ErrorProfilePage} />
            <Route exact path={URLS.NOT_ALLOWED_PAGE} component={PAGES.NotAllowedPage} />
            <PrivateRoutesList />
        </Switch>
    );
};
export default Routes;
