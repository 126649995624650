import React from "react";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";

const PREFIX = "AutoComplete";

const classes = {
    chip: `${PREFIX}Chip`,
    chipIcon: `${PREFIX}ChipIcon`,
    chipFocused: `${PREFIX}ChipFocused`
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.chip}`]: {
        margin: theme.spacing(0.5, 0.25),
        backgroundColor: theme.palette.common["blue"],
        color: "white"
    },

    [`& .${classes.chipIcon}`]: {
        color: "white"
    },

    [`& .${classes.chipFocused}`]: {
        backgroundColor: "red"
    }
}));

const MultiValue = props => {
    return (
        <Chip
            tabIndex={-1}
            label={<Typography variant={"button"}>{props.children}</Typography>}
            className={classes.chip}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon className={classes.chipIcon} {...props.removeProps} />}
        />
    );
};

const components = {
    MultiValue
};

const AutoComplete = props => {
    const theme = useTheme();
    const {
        className,
        placeholder,
        onChange,
        onInputChange,
        disabled,
        value,
        suggestions,
        is_multi,
        onFocus
    } = props;

    const style = {
        container: provided => ({
            ...provided,
            fontFamily: "Roboto Condensed",
            boxShadow: "0 1px 5px rgba(0,0,0,0.10), 0 1px 5px rgba(0,0,0,0.10)",
            borderRadius: theme.spacing(1),
            color: theme.palette.common["blue"],
            outline: "none"
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: theme.spacing(1),
            padding: "5px 5px",
            border: 0,
            boxShadow: 0,
            color: theme.palette.common["blue"]
        }),
        singleValue: provided => ({
            color: theme.palette.common["blue"]
        }),
        menu: provided => ({
            ...provided,
            borderRadius: theme.spacing(0),
            textAlign: "left",
            color: "black"
        }),
        option: (provided, state) => ({
            ...provided,
            color: "black",
            padding: "10px 16px",
            backgroundColor: state.isSelected
                ? "lightgrey"
                : state.isFocused || state.isActive
                ? "lightgrey"
                : "white"
        })
    };

    return (
        <Root className={className}>
            <Select
                styles={style}
                inputId="react-select-multiple"
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                options={suggestions}
                components={components}
                isMulti={is_multi}
                onInputChange={onInputChange}
                isDisabled={disabled}
                onFocus={onFocus}
                isClearable
                {...props}
            />
        </Root>
    );
};

AutoComplete.propTypes = {
    className: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    suggestions: PropTypes.array,
    is_multi: PropTypes.bool
};

export default AutoComplete;
