const prefix = "Results";

export const SET_SELECTED_FILTER = `${prefix}/SET_SELECTED_FILTER`;
export const SET_SELECTED_BOX_FILTER = `${prefix}/SET_SELECTED_BOX_FILTER`;
export const SET_MY_RESULTS = `${prefix}/SET_MY_RESULTS`;
export const SET_MY_TEAM_RESULTS = `${prefix}/SET_MY_TEAM_RESULTS`;
export const SET_MY_SURVEY = `${prefix}/SET_MY_SURVEY`;
export const SET_MY_COUNTRY_RESULTS = `${prefix}/SET_MY_COUNTRY_RESULTS`;
export const SET_MY_COUNTRY_RESULTS_UNITED_BLOOMING = `${prefix}/SET_MY_COUNTRY_RESULTS_UNITED_BLOOMING`;
export const SET_MY_COUNTRY_RESULTS_UNITED_THEMATIC = `${prefix}/SET_MY_COUNTRY_RESULTS_UNITED_THEMATIC`;
export const SET_MY_COUNTRY_RESULTS_UNITED_QUESTION = `${prefix}/SET_MY_COUNTRY_RESULTS_UNITED_QUESTION`;
export const SET_MY_LOADER = `${prefix}/SET_MY_LOADER`;
export const SET_FETCHING_COUNTRY = `${prefix}/SET_FETCHING_COUNTRY`;
export const SET_FETCHING_TEAMS = `${prefix}/SET_FETCHING_TEAMS`;
export const SET_MY_TEAM_N_RESULTS = `${prefix}/SET_MY_TEAM_N_RESULTS`;
export const SET_MY_TEAM_1_RESULTS = `${prefix}/SET_MY_TEAM_1_RESULTS`;
export const SET_MY_COUNTRY_PARTICIPATION_RATE = `${prefix}/SET_MY_COUNTRY_PARTICIPATION_RATE`;
export const SET_MY_VERIFY_LOADER = `${prefix}/SET_MY_VERIFY_LOADER`;
export const SET_MY_VERIFY_ERROR_403_TEAM = `${prefix}/SET_MY_VERIFY_ERROR_403_TEAM`;
export const SET_MY_VERIFY_ERROR_403_TEAM_ONE = `${prefix}/SET_MY_VERIFY_ERROR_403_TEAM_ONE`;
export const SET_MY_VERIFY_ERROR_403_TEAM_N = `${prefix}/SET_MY_VERIFY_ERROR_403_TEAM_N`;
export const SET_MY_MEDIA_CONTENT = `${prefix}/SET_MY_MEDIA_CONTENT`;
export const SET_COUNTRY_NOT_AUTHORIZED = `${prefix}/SET_COUNTRY_NOT_AUTHORIZED`;
export const SET_SURVEY_TITLE_TRANSLATION = `${prefix}/SET_SURVEY_TITLE_TRANSLATION`;

// ===========================================================================
//                      Reworked Redux for Results
// ===========================================================================

export const SET_SURVEY = `${prefix}/SET_SURVEY`;
export const SET_MY = `${prefix}/SET_MY`;
export const SET_TEAM_0 = `${prefix}/SET_TEAM_0`;
export const SET_TEAM_1 = `${prefix}/SET_TEAM_1`;
export const SET_TEAM_N = `${prefix}/SET_TEAM_N`;
export const SET_COUNTRY = `${prefix}/SET_COUNTRY`;
export const SET_UNITED = `${prefix}/SET_UNTIED`;
