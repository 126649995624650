export const getCampaigns = (queryParams = []) => ({
    method: "GET",
    suffix: `/campaigns?${queryParams.map(param => param).join("&")}`
});

export const getPeopleCampaign = params => ({
    method: "GET",
    suffix: `/dtb_users/search_users_campaign?${params}`
});

export const postCampaign = () => ({
    method: "POST",
    suffix: "/campaigns"
});

export const putCampaign = id => ({
    method: "PUT",
    suffix: `/campaigns/${id}`
});
